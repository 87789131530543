/** @format */

import React, {createContext, useState, useEffect} from 'react';
import {jwtDecode} from 'jwt-decode';
import {User} from '../types/user';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {register as registerApi, login as loginApi, LoginProps, validateToken} from '../api/auth';
import {useSnackbar} from 'notistack';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthContextType {
    user: User | null;
    login: (username: string, password: string, rememberMe: boolean) => Promise<void>;
    register: (email: string, username: string, password: string) => Promise<void>;
    logout: (onSuccess?: () => void) => void;
    useRegisterMutation: any;
    hasCheckedForToken: boolean;
}

const AuthProvider = ({children}) => {
    const queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();

    const tokenValidationQuery = useQuery('validateToken', validateToken, {
        refetchInterval: false,
        refetchOnMount: false,
        enabled: false,
    });
    const [hasCheckedForToken, setHasCheckedForToken] = useState(false);
    const [user, setUser] = useState<User>(null);
    const [token, setToken] = useState<string | null>(null);

    const useLoginMutation = useMutation(loginApi);

    const useRegisterMutation = useMutation(registerApi);

    useEffect(() => {
        let storageToken = localStorage.getItem('token');
        if (!storageToken) storageToken = sessionStorage.getItem('token');

        if (storageToken) {
            setToken(storageToken);
            tokenValidationQuery.refetch().catch((err) => {
                console.error(err);
                logout();
                setHasCheckedForToken(true);
            });
        } else {
            setHasCheckedForToken(true);
        }
    }, []);

    useEffect(() => {
        if (tokenValidationQuery.data === undefined) return;

        if (tokenValidationQuery.data.tokenValid === true) {
            const decodedToken = jwtDecode(token);
            setUser(decodedToken as User);
            setHasCheckedForToken(true);
        } else {
            enqueueSnackbar('Your session has expired. Please log in again.', {variant: 'info'});
            logout();
            setHasCheckedForToken(true);
        }
    }, [tokenValidationQuery.data]);

    const login = (username: string, password: string, rememberMe: boolean) => {
        return new Promise<void>((resolve, reject) => {
            useLoginMutation.mutate({username, password} as LoginProps, {
                onSuccess: (data) => {
                    if (rememberMe) {
                        localStorage.setItem('token', data.token);
                    } else {
                        sessionStorage.setItem('token', data.token);
                    }
                    decodeToken(data.token);
                    resolve();
                },
                onError: (error) => {
                    reject(error);
                },
            });
        });
    };

    const register = async (email: string, username: string, password: string) => {
        return new Promise<void>((resolve, reject) => {
            useRegisterMutation.mutate({email, username, password} as LoginProps, {
                onSuccess: () => {
                    resolve();
                },
                onError: (error) => {
                    reject(error);
                },
            });
        });
    };

    const decodeToken = (token: string) => {
        const decodedToken = jwtDecode(token);
        setUser(decodedToken as User);
    };

    const logout = (onSuccess?: () => void) => {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        setUser(null);
        queryClient.clear();
        if (onSuccess) onSuccess();
    };

    return (
        <AuthContext.Provider value={{user, login, register, logout, useRegisterMutation, hasCheckedForToken}}>
            {children}
        </AuthContext.Provider>
    );
};

export {AuthContext, AuthProvider};
