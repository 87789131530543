import React, {useContext, useEffect, useState} from "react";
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    LinearProgress,
    Select,
    MenuItem,
    Pagination, Button, SelectChangeEvent, CircularProgress, IconButton, Menu
} from "@mui/material";
import {getHighscores} from "../../../api/highscore";
import {useMutation, useQuery} from "react-query";
import {AuthContext} from "../../../contexts/AuthContext";
import {blockUser, unblockUser} from "../../../api/auth";
import {MoreVert} from "@mui/icons-material";
import {Highscore} from "../../../types/highscore";
import {useSnackbar} from "notistack";

const Leaderboard = () => {
    const [filter, setFilter] = useState('all');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [scope, setScope] = useState<'global' | 'personal'>('global');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [highscoreSelected, setHighscoreSelected] = useState({} as Highscore);
    const {enqueueSnackbar} = useSnackbar();
    const open = Boolean(anchorEl);

    const {user} = useContext(AuthContext);

    const leaderBoardQuery = useQuery(['leaderboard'], () => {
        const offset = (page - 1) * limit;
        const userId = scope === 'personal' ? user.id : null;
        switch (filter) {
            case 'day':
                return getHighscores(userId, 'day', limit, offset);
            case 'month':
                return getHighscores(userId, 'month', limit, offset);
            case 'year':
                return getHighscores(userId, 'year', limit, offset);
            default:
                return getHighscores(userId, 'all', limit, offset);
        }
    }, {
        keepPreviousData: true,
        refetchInterval: false,
    });

    const userBlockedMutation = useMutation('blockUser', blockUser)
    const userUnblockedMutation = useMutation('unblockUser', unblockUser);

    const handleBlockUser = () => {
        userBlockedMutation.mutate(highscoreSelected.userId, {
            onSuccess: () => {
                enqueueSnackbar(`User has been blocked successfully!`, {variant: "success"});
                leaderBoardQuery.refetch();
            }
        });
    }

    const handleUnblockUser = () => {
        userUnblockedMutation.mutate(highscoreSelected.userId, {
            onSuccess: () => {
                enqueueSnackbar(`User has been unblocked successfully!`, {variant: "success"});
                leaderBoardQuery.refetch();
            }
        });
    }

    const handleLimitChange = (event: SelectChangeEvent<number>) => {
        const newLimit = parseInt(event.target.value as string);
        setLimit(newLimit);
        setPage(1);
    };

    const handleActionsMenuClick = (event: React.MouseEvent<HTMLElement>, highscore: Highscore) => {
        setAnchorEl(event.currentTarget);
        setHighscoreSelected(highscore);
    };

    const handleActionsMenuClose = () => {
        setAnchorEl(null);
    };

    const handleScopeChange = (scope: 'global' | 'personal') => {
        setScope(scope);
        setPage(1);
    }

    const isUserBlocked = (highscore: Highscore) => highscore?.username?.match(/^\*+$/) ? true : false;

    const getDisplayName = (highscore: Highscore) => {
        return highscore.username + (highscore.userId !== user.id ? "" : ' (You)');
    }

    useEffect(() => {
        leaderBoardQuery.refetch();
    }, [limit, page, scope]);


    if (leaderBoardQuery.status === "loading" || leaderBoardQuery.data === undefined) {
        return (
            <Container maxWidth="sm">
                <Box sx={{padding: 5}}>
                    <Typography variant="h5" component="div">
                        Loading Leaderboard...
                    </Typography>
                    <LinearProgress/>
                </Box>
            </Container>
        )
    }

    if (leaderBoardQuery.status === "error") {
        return (
            <Container maxWidth="sm">
                <Box sx={{padding: 5}}>
                    <Typography variant="h5" component="div">
                        Error fetching Leaderboard
                    </Typography>
                </Box>
            </Container>
        )
    }

    const BlockAction = () => {
        const click = (func: () => void) => {
            func();
            handleActionsMenuClose();
        }

        if (isUserBlocked(highscoreSelected))
            return <MenuItem onClick={() => click(() => handleUnblockUser())}>Unblock</MenuItem>

        return (
            <MenuItem onClick={() => click(() => handleBlockUser())} disabled={highscoreSelected.userId === user.id}>
                Block
            </MenuItem>
        )
    }

    const Actions = () => (
        <>
            <BlockAction />
        </>
    );

    return (
        <Container maxWidth="sm">
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleActionsMenuClose}
            >
                <Actions/>
            </Menu>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Player</TableCell>
                            <TableCell>Score</TableCell>
                            <TableCell>Best T-Force Member</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            leaderBoardQuery.data &&
                            leaderBoardQuery.data.highScores.map((highscore, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body1" component="div">
                                            {getDisplayName(highscore)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{highscore.score}</TableCell>
                                    <TableCell>{highscore.difficulty}</TableCell>
                                    <TableCell align="right" component="th" scope="row">
                                        <IconButton
                                            onClick={(e) => handleActionsMenuClick(e, highscore)}
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        >
                                            <MoreVert/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{padding: 1}}>
                <Select
                    value={filter}
                    onChange={(event) => setFilter(event.target.value)}
                    sx={{marginX: 1}}
                >
                    <MenuItem value="all">All Time</MenuItem>
                    <MenuItem value="day">Today</MenuItem>
                    <MenuItem value="month">This Month</MenuItem>
                    <MenuItem value="year">This Year</MenuItem>
                </Select>
                <Select
                    value={scope}
                    onChange={(event) => handleScopeChange(event.target.value as 'global' | 'personal')}
                    sx={{marginX: 1}}
                >
                    <MenuItem value="global">Global</MenuItem>
                    <MenuItem value="personal">Personal</MenuItem>
                </Select>
                <Select
                    value={limit}
                    onChange={(event) => handleLimitChange(event)}
                    sx={{marginX: 1}}
                >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem defaultChecked={true} value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={250}>250</MenuItem>
                </Select>
                <Button onClick={() => leaderBoardQuery.refetch()} disabled={leaderBoardQuery.isLoading}
                        sx={{marginX: 1}}>
                    {leaderBoardQuery.isLoading ? <CircularProgress size={24}/> : "Refresh"}
                </Button>
            </Box>
            <Pagination
                count={leaderBoardQuery.data?.totalPages || 1}
                page={page}
                onChange={(event, value) => setPage(value)}
                sx={{padding: 1}}
            />
        </Container>
    );
}

export default Leaderboard;
