import {Box, Button, Container, Modal, Paper, TextField, Typography} from "@mui/material";
import React from "react";
import {Chapter} from "../../../types/chapter";
import ImageSelectField from "../ImageSelectField";
import Sidebar from "../sidebar/SideBar";

interface CreateChapterModalProps {
    isOpen: boolean;
    onClose: () => void;
    onCreate: (chapter: Chapter) => void;
}

const CreateChapterModal: React.FC<CreateChapterModalProps> = ({isOpen, onClose, onCreate}) => {
    const [newChapter, setNewChapter] = React.useState<Chapter>({
        id: -1,
        title: "",
        description: "",
        coverImageUrl: "",
        sequence: 0,
        isDraft: true,
        completed: false,
    });

    const handleNameChange = (event) => setNewChapter({...newChapter, title: event.target.value});
    const handleDescriptionChange = (event) => setNewChapter({...newChapter, description: event.target.value});
    const handleCoverChange = (url) => setNewChapter({...newChapter, coverImageUrl: url});

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="create-chapter-modal"
            aria-describedby="create-chapter-modal-description"
        >
            <Container sx={{
                width: "70vw",
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Paper sx={{padding: 2}}>
                    <Typography variant="h6" id="modal-title" gutterBottom>
                        Create A Chapter
                    </Typography>

                    <Box sx={{display: "flex"}}>
                        <Box sx={{width: "50%", flexDirection: "column", paddingLeft: 1, gap: 2}}>
                            <ImageSelectField
                                onImageChange={handleCoverChange}
                                label={"Chapter Cover"}
                                imageUrl={newChapter.coverImageUrl}
                            />
                        </Box>

                        <Box sx={{width: "50%", flexDirection: "column", paddingRight: 1, gap: 2}}>
                            <TextField onChange={handleNameChange} sx={{marginBottom: 1}} name={"title"} label={"Title"}
                                       variant={"outlined"} fullWidth/>
                            <TextField onChange={handleDescriptionChange} name={"description"} label={"Description"}
                                       multiline rows={4} variant={"outlined"}
                                       fullWidth/>
                        </Box>
                    </Box>

                    <Box
                        sx={{marginTop: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Button onClick={onClose}>Close</Button>
                        <Button variant={"outlined"} color={"success"}
                                onClick={(e) => onCreate(newChapter)}>Create</Button>
                    </Box>
                </Paper>
                <Sidebar/>
            </Container>
        </Modal>
    )
}

export default CreateChapterModal;
