import Phaser from "phaser";
import playerSprite from './sprites/aurorakicker7.png';
import soccerBallSprite from './sprites/soccerball.png';
import goalSprite from './sprites/Goal.png';
import fieldSprite from './sprites/field.png';
import dustmiteSprite from './sprites/DUSTMITE.png';
import emotionSprite from './sprites/EMOTION.png';
import moldSprite from './sprites/MOLD.png';
import pollenSprite from './sprites/POLLEN.png';
import strongsmellsSprite from './sprites/SMELLS.png';
import weatherSprite from './sprites/WX-WEATHER.png';
import goalEffectSprite from './sprites/goalEFFECT.png';
import buttonSprite from './sprites/arrow.png';
import Enemy from "./enemy";
import gameConstants from "./game-constants";

const aimingLineLength = 350;
const soccerBallSpeed = 800;
const maxEnemySpawnTryCount = 10;

const { titleStyle, scoreStyle } = gameConstants.textDecoration;

class PlayScene extends Phaser.Scene {
    handlerScene = false
    sceneStopped = false

    player;
    playerSpeed = 450;
    soccerBall;
    maxBounces = 8;
    currentBounces = 0;
    currentSoccerBallVelocityX = 1;
    currentSoccerBallVelocityY = 1;
    isKicking = false;
    cursors;
    goal;
    playerRunTweenRunning = false;
    playerRunTween;
    canBounceOnWall = true;

    enemies;
    enemyTypes = [
        {type: 'weather', score: 100},
        {type: 'pollen', score: 250},
        {type: 'strong smells', score: 500},
        {type: 'mold', score: 1000},
        {type: 'emotion', score: 2500},
        {type: 'dust mites', score: 5000}
    ];
    enemyHits = Array(this.enemyTypes.length).fill(0);

    aimingLine;
    aimingAngle = 0;
    aimLength = aimingLineLength;

    triesText;
    scoreText;
    tries = 5;
    score = 0;
    wallBounds;
    width;
    height;

    maxEnemySpawnPerWaveCount = 5;
    minEnemySpawnPerWaveCount = 2;

    constructor() {
        super('game')

        this.kickSoccerBall = this.kickSoccerBall.bind(this);
        this.rotationSpeed = 0;
        this.rotationTime = 0;
    }

    init() {
        this.handlerScene = false;
        this.sceneStopped = false;
        this.playerSpeed = 450;
        this.maxBounces = 8;
        this.currentBounces = 0;
        this.currentSoccerBallVelocityX = 1;
        this.currentSoccerBallVelocityY = 1;
        this.isKicking = false;
        this.playerRunTweenRunning = false;
        this.canBounceOnWall = true;
        this.enemyHits = Array(this.enemyTypes.length).fill(0);
        this.aimingAngle = 0;
        this.aimLength = aimingLineLength;
        this.tries = 5;
        this.score = 0;
        this.maxEnemySpawnPerWaveCount = 5;
        this.minEnemySpawnPerWaveCount = 2;
        this.rotationSpeed = 0;
        this.rotationTime = 0;

        this.isHoldingAimButton = false;
        this.isHoldingLeftButton = false;
        this.isHoldingRightButton = false;

        this.game.socket.emit('startGame', {userId: this.game.userId});

        this.game.socket.on('goalScored', (gs) => {
            let gameState = gs.state;
            this.clearEnemies(gameState);
            this.resetSoccerBall();
            this.spawnEnemies(gameState);
            this.validateGameStateAttempts(gameState);
        });

        this.game.socket.on('gameOver', (gs) => {
            let gameState = gs.state;
            let ranks = gs.ranks;
            this.scene.start('game-over', {score: gameState.playerScore, ranks});
        });

        this.game.socket.on('enemyHit', (gs) => {
            let gameState = gs.state;
            this.validateGameStateAttempts(gameState);
            this.validateGameStateEnemies(gameState);
        });
    }

    shutdown() {
        this.removeEventListeners();
    }

    preload() {
        this.load.image('player', playerSprite);
        this.load.image('soccerBall', soccerBallSprite);
        this.load.image('goal', goalSprite);
        this.load.image('field', fieldSprite);
        this.load.image('dust mites', dustmiteSprite);
        this.load.image('emotion', emotionSprite);
        this.load.image('mold', moldSprite);
        this.load.image('pollen', pollenSprite);
        this.load.image('strong smells', strongsmellsSprite);
        this.load.image('weather', weatherSprite);
        this.load.image('goalEffect', goalEffectSprite);
        this.load.image('arrowButton', buttonSprite);
    }


    create() {
        this.createNewGame()
        this.createButtons();
    }

    update() {
        this.handleControls();
        this.handleSoccerBall();
        this.handleAimingLine();

        if (this.isKicking) {
            this.soccerBall.angle += 6;
        }

        this.rotationTime += 0.01;
        this.rotationSpeed = 5 * Math.sin(this.rotationTime);
    }

    handleAimingLine() {
        this.aimingLine.clear(true, true);

        if (!this.isAiming) {
            return;
        }

        const dashSize = 25;
        const gapSize = 10;
        const numSegments = this.aimLength / (dashSize + gapSize);

        for (let i = 0; i < numSegments; i++) {
            const x = this.player.x + i * (dashSize + gapSize) * Math.cos(Phaser.Math.DegToRad(this.aimingAngle));
            const y = this.player.y + i * (dashSize + gapSize) * Math.sin(Phaser.Math.DegToRad(this.aimingAngle));

            const segment = this.add.line(x, y, 0, 0, dashSize, 0, 0xffffff);
            segment.setOrigin(0, 0.5);
            segment.setLineWidth(5);
            segment.setAngle(this.aimingAngle);
            segment.setDepth(0);
            this.aimingLine.add(segment);
        }
    }

    createButtons() {
        const { controlButtonStyle } = gameConstants.textDecoration;
        const width = this.sys.canvas.width;
        const height = this.sys.canvas.height;

        const handleDown = (func, button) => {
            func();
            button.tint = 300 * 0x424242;
        }

        const handleUp = (func, button) => {
            func();
            button.tint = 0xffffff;
        }

        const aimButton = this.physics.add.sprite(width * 0.5, height - 100, 'soccerBall').setScale(0.2).setOrigin(0.5).setInteractive();
        aimButton.on('pointerdown', () => handleDown(() => this.isHoldingAimButton = true, aimButton));
        aimButton.on('pointerup', () => handleUp(() => this.isHoldingAimButton = false, aimButton));

        const leftButton = this.physics.add.sprite(width * 0.08, height - 200, 'arrowButton').setScale(0.17).setOrigin(0.5).setInteractive();
        leftButton.on('pointerdown', () => handleDown(() => this.isHoldingLeftButton = true, leftButton));
        leftButton.on('pointerup', () => handleUp(() => this.isHoldingLeftButton = false, leftButton));

        const rightButton = this.physics.add.sprite(width * 0.92, height - 200, 'arrowButton').setScale(0.17).setOrigin(0.5).setInteractive();
        rightButton.setFlip(true, false)
        rightButton.on('pointerdown', () => handleDown(() => this.isHoldingRightButton = true, rightButton));
        rightButton.on('pointerup', () => handleUp(() => this.isHoldingRightButton = false, rightButton));
    }

    handleControls() {
        this.player.setVelocity(0);

        if (this.cursors.A.isDown || this.cursors.LEFT.isDown || this.isHoldingLeftButton) {
            if (!this.playerRunTweenRunning) this.playerRunTween.resume();
            this.player.setVelocityX(-this.playerSpeed);
        } else if (this.cursors.D.isDown || this.cursors.RIGHT.isDown || this.isHoldingRightButton) {
            if (!this.playerRunTweenRunning) this.playerRunTween.resume();
            this.player.setVelocityX(this.playerSpeed);
        } else {
            this.playerRunTweenRunning = false;
            this.playerRunTween.pause();
        }

        if (!this.isKicking) {
            if (this.cursors.SPACE.isDown || this.isHoldingAimButton) {
                this.isAiming = true;
            } else if (this.isAiming) {
                this.kickSoccerBall();
            }
        }
    }

    handleSoccerBall() {
        if (this.isKicking) {
            if (this.soccerBall.y <= (this.soccerBall.displayHeight / 2)) {
                this.onOutOfBounds();
            }
            if (this.soccerBall.x < this.soccerBall.displayWidth / 1.5) {
                this.onWallHit();
            } else if (this.soccerBall.x >= (this.wallBounds - this.soccerBall.displayWidth / 1.5)) {
                this.onWallHit();
            }
        }
    }

    validateGameStateEnemies(gameState) {
        const enemiesOnField = gameState.enemiesOnField;
        for (let i = 0; i < enemiesOnField.length; i++) {
            let enemy = this.findEnemyById(enemiesOnField[i].id);
            if (!enemy) {
                console.error("Enemy out of sync with server");
                continue;
            }

            if (enemy.getData('type') !== enemiesOnField[i].type) {
                console.error("Enemy type out of sync with server");
            }
        }
    }

    findEnemyById(id) {
        return this.enemies.getChildren().find(enemy => enemy.id === id);
    }

    validateGameStateScore(gameState) {
        if (gameState.playerScore !== this.score) {
            this.score = gameState.playerScore;
            this.scoreText.setText(`Score: ${this.score}`);
        }
    }

    validateGameStateAttempts(gameState) {
        if (gameState.attemptsLeft !== this.tries) {
            this.tries = gameState.attemptsLeft;
            this.triesText.setText(`Misses: ${this.tries}`);
        }
    }

    removeEventListeners() {
        this.game.socket.off('goalScored');
        this.game.socket.off('gameOver');
        this.game.socket.off('enemyHit');
    }

    createNewGame() {
        const height = this.sys.canvas.height / 2;
        const width = this.sys.canvas.width / 2;

        this.bg = this.add.image(width, height, 'field').setOrigin(.5).setScale(1);
        this.bg.setDisplaySize(this.sys.canvas.width, this.sys.canvas.height)

        this.player = this.physics.add.sprite(width, (this.sys.canvas.height) - 300, 'player');
        this.player.setScale(0.4);
        this.player.setDepth(1);
        this.player.setCollideWorldBounds(true);

        this.isAiming = false;

        this.soccerBall = this.physics.add.sprite(this.player.x, this.player.y, 'soccerBall').setVisible(false);
        this.soccerBall.setScale(0.15);
        this.soccerBall.setBounce(1);
        this.soccerBall.setCollideWorldBounds(true);

        const aimSpeed = 2000;

        this.tweens.add({
            targets: this,
            aimingAngle: {from: -180, to: 0},
            duration: aimSpeed,
            yoyo: true,
            repeat: -1,
            ease: 'Sine.easeInOut'
        });

        this.playerRunTween = this.tweens.add({
            targets: this.player,
            angle: {from: -5, to: 5},
            duration: 200,
            yoyo: true,
            repeat: -1,
            ease: 'Sine.easeInOut',
            onStart: () => {this.playerRunTweenRunning = true}
        });

        this.wallBounds = this.sys.game.canvas.width;

        const goal = this.physics.add.sprite(width, 375, 'goal').setImmovable(true).setScale(0.25);

        this.goal = goal;

        this.tweens.add({
            targets: goal,
            scale: {from: 0.23, to: 0.25},
            duration: 150,
            yoyo: true,
            ease: 'Power2'
        });

        this.aimingLine = this.add.group();

        this.physics.add.collider(this.soccerBall, goal, this.onGoalHit, null, this);

        this.cursors = this.input.keyboard.addKeys('A,D,LEFT,RIGHT,SPACE');

        this.enemies = this.physics.add.group({
            classType: Enemy,
            runChildUpdate: true,
            createCallback: (enemy) => {enemy.setDepth(0);},
        });

        this.triesText = this.add.text(16, 116, `Misses: ${this.tries}`, {...titleStyle, fontStyle: ""});
        this.scoreText = this.add.text(16, 16, 'Score: 0', {...titleStyle, fontStyle: ""});

        this.physics.add.collider(this.soccerBall, this.enemies, (s, e) => {this.onEnemyHit(e)}, null, this);
    }

    spawnEnemies(gameState) {
        const enemiesToSpawn = gameState.enemiesOnField;
        if (enemiesToSpawn.length === 0 || !enemiesToSpawn) {
            return;
        }

        let spawnCalls = [];
        let timeFactor = 20;
        for (let i = 0; i < enemiesToSpawn.length; i++) {
            let timeToSpawn = i * timeFactor;
            spawnCalls.push(
                this.time.delayedCall(timeToSpawn, () => {
                    this.createEnemy(enemiesToSpawn[i]);
                })
            );
        }

        // when enemies are spawned, validateGameStateEnemies
        this.time.delayedCall(spawnCalls.length * timeFactor, () => {
            //this.validateGameStateEnemies(enemiesToSpawn);
        });
    }

    clearEnemies(gameState) {
        let enemiesToDestroy = [];
        let scoreToAdd = [];

        this.enemies.getChildren().forEach(enemy => {
            enemiesToDestroy.push(enemy);
        });

        enemiesToDestroy.forEach(enemy => {
            this.showPoints(enemy.x, enemy.y, enemy.getData('points'));
            scoreToAdd.push(enemy.getData('points'));
            this.tweens.add({
                targets: enemy,
                scale: 0,
                angle: 360,
                ease: 'Power2',
                duration: 300,
                onComplete: () => {
                    if (enemy.tweens !== undefined) enemy.tweens.killAll();
                    enemy.destroy();
                }
            });
        });

        let scoreAnimTime = 150 - (enemiesToDestroy.length * 10);

        this.tweens.add({
            targets: this.scoreText,
            scale: 1.05,
            duration: scoreAnimTime,
            yoyo: true,
            ease: 'Power2',
            loop: enemiesToDestroy.length,
            onLoop: () => {
                this.updateScore(scoreToAdd.pop());
            },
            onComplete: () => {
                this.validateGameStateScore(gameState);
            }
        });

        this.enemies.clear();
    }

    showPoints(x, y, amount) {
        let scoreText = this.add.text(x, y, amount, scoreStyle).setOrigin(0.5);

        this.tweens.add({
            targets: scoreText,
            y: scoreText.y - 50,
            alpha: 0,
            duration: 2200,
            ease: 'Power2',
            onComplete: () => {scoreText.destroy();}
        });
    }

    showGoalEffect(x, y) {
        let goalEffect = this.add.image(x, y, 'goalEffect');
        goalEffect.setScale(0.1);
        goalEffect.setAlpha(0.7);

        this.tweens.add({
            targets: goalEffect,
            scale: 0.2,
            alpha: 0,
            duration: 1250,
            ease: 'Power2',
            onComplete: () => {goalEffect.destroy();}
        });
    }

    createEnemy(enemyData) {
        const enemyPosition = this.getValidEnemyPosition(0);
        const enemy = new Enemy(this, enemyPosition.x, enemyPosition.y, enemyData, this.soccerBall);
        this.physics.world.enable(enemy);
        this.enemies.add(enemy);
    }

    getValidEnemyPosition(tries = 0) {
        if (tries >= maxEnemySpawnTryCount) {
            return {x: 0, y: 0};
        }

        let position = {
            x: Phaser.Math.Between(100, this.sys.canvas.width - 100),
            y: Phaser.Math.Between(475, this.sys.canvas.height - 500)
        };
        let tooClose = this.isPositionTooCloseToEnemies(position);

        if (tooClose) {
            return this.getValidEnemyPosition(tries + 1);
        }

        return position;
    }

    isPositionTooCloseToEnemies(position) {
        const minDistance = 250;

        for (let enemy of this.enemies.getChildren()) {
            let distance = Phaser.Math.Distance.Between(position.x, position.y, enemy.x, enemy.y);
            if (distance < minDistance) {
                return true;
            }
        }

        return false;
    }

    onOutOfBounds() {
        this.game.socket.emit('outOfBounds');
        this.reduceTries();
    }

    onWallHit() {
        if (!this.canBounceOnWall) return;

        this.currentBounces++;
        if (this.currentBounces >= this.maxBounces) this.resetSoccerBall();

        this.canBounceOnWall = false;
        this.time.addEvent({
            delay: 100,
            callback: () => {
                this.canBounceOnWall = true;
            }
        });
    }

    onGoalHit() {
        this.game.socket.emit('goalScored');
        let goalScore = 100;

        this.showPoints(this.soccerBall.x, this.soccerBall.y, goalScore);
        this.showGoalEffect(this.soccerBall.x, this.soccerBall.y);
        this.updateScore(goalScore);

        this.tweens.add({
            targets: this.goal,
            scale: {from: 0.23, to: 0.25},
            duration: 125,
            yoyo: true,
            ease: 'Power2'
        });

        this.resetSoccerBall();
    }

    updateScore(scoreIncrease) {
        this.score += scoreIncrease;
        this.scoreText.setText(`Score: ${this.score}`);
    }

    onEnemyHit(enemy) {
        if (!this.isKicking) return;
        this.game.socket.emit('enemyHit', enemy.id);

        this.tweens.add({
            targets: enemy,
            scale: 0,
            ease: 'Power2',
            duration: 300,
            angle: 360,
            onComplete: () => {
                if (enemy.tweens !== undefined) enemy.tweens.killAll();
                this.enemies.remove(enemy, true, true);
            }
        });

        // smoke effect

        this.reduceTries();
    }

    reduceTries() {
        this.tries--;
        this.triesText.setText(`Misses: ${this.tries}`);

        this.tweens.add({
            targets: this.triesText,
            scale: 1.05,
            duration: 200,
            yoyo: true,
            ease: 'Power2'
        });

        this.resetSoccerBall();

        if (this.tries <= 0) {
            // Game over
            //this.scene.start('game-over', {score: this.score});
        }
    }

    resetSoccerBall() {
        this.soccerBall.setVisible(false);
        this.isKicking = false;
        this.soccerBall.setPosition(this.player.x, this.player.y);
        this.soccerBall.setVelocity(0, 0);
        this.currentBounces = 0;
    }

    kickSoccerBall() {
        if (!this.soccerBall) {
            console.error('Soccer ball is not initialized');
            return;
        }

        if (!this.isKicking) {
            this.isKicking = true;
            this.isAiming = false;
            this.soccerBall.setPosition(this.player.x, this.player.y).setVisible(true);

            const radians = Phaser.Math.DegToRad(this.aimingAngle);

            const velocityX = soccerBallSpeed * this.currentSoccerBallVelocityX * Math.cos(radians);
            const velocityY = soccerBallSpeed * this.currentSoccerBallVelocityY * Math.sin(radians);

            this.soccerBall.setVelocity(velocityX, velocityY);
        }
    }
}

export default PlayScene;
