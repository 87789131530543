import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {createBrowserRouter, Link, RouterProvider, useRouteError} from "react-router-dom";
import ChapterReader from "./components/chapter-reader/ChapterReader";
import ChapterSelector from "./components/chapter-editor/ChapterSelector";
import ChapterEditor from "./components/chapter-editor/ChapterEditor";
import ChapterGrid from "./components/chapter-grid/ChapterGrid";
import {Container, ThemeProvider, Typography} from "@mui/material";
import {SnackbarProvider} from "notistack";
import {QueryClient, QueryClientProvider} from "react-query";
import {ChapterEditorProvider} from "./contexts/ChapterEditorContext";
import {ConfirmationModalProvider} from "./contexts/ConfirmationModalContext";
import {AuthProvider} from "./contexts/AuthContext";
import LoginPage from "./components/login-page/LoginPage";
import RegisterPage from "./components/login-page/RegisterPage";
import AdminAuthGuard from "./components/app/AdminAuthGuard";
import VerifyEmailPage from "./components/login-page/VerifyEmailPage";
import SoccerShootoutWrapper from "./components/soccer-shootout/SoccerShootoutWrapper";
import Leaderboard from "./components/soccer-shootout/leaderboard/Leaderboard";
import Game from "./components/soccer-shootout/game/Game";
import AccountPage from "./components/account/AccountPage";
import ResetPage from "./components/login-page/ResetPage";
import {AxiosProvider} from "./contexts/AxiosProvider";
import ForgotPasswordPage from "./components/login-page/ForgotPasswordPage";
import customTheme from "./theme";

// import {ReactQueryDevtools} from "react-query/devtools";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error('Uncaught error:', error, errorInfo);
    }

    render() {
        return this.props.children;
    }
}

const GeneralError = () => {
    const error = useRouteError();
    return (
        <Container maxWidth="md">
            <Typography variant="h4">Oops! Something went wrong.</Typography>
            <Link to={"/"}>Back to the Homepage</Link>
        </Container>
    );
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <GeneralError/>,
        hasErrorBoundary: true,
        children: [
            {
                index: true,
                element: <ChapterGrid/>
            },
            {
                path: 'reset/:code',
                element: <ResetPage/>
            },
            {
                path: 'forgot-password',
                element: <ForgotPasswordPage/>
            },
            {
                path: 'verify-email',
                element: <VerifyEmailPage/>,
            },
            {
                path: "chapter/:chapterId",
                element: <ChapterReader/>,
            },
            {
                path: "chapter-select",
                element: <AdminAuthGuard component={<ChapterSelector/>}/>,
            },
            {
                path: "chapter-editor/:chapterId",
                element: <AdminAuthGuard component={<ChapterEditor/>}/>,
            },
            {
                path: "soccer-shootout",
                element: <SoccerShootoutWrapper/>,
                children: [
                    {
                        path: "game",
                        element: <Game/>,
                    },
                    {
                        path: "leaderboard",
                        element: <Leaderboard/>,
                    }
                ]
            },
            {
                path: 'account',
                element: <AccountPage/>,
            },
            {
                path: 'login',
                element: <LoginPage/>,
            },
            {
                path: 'register',
                element: <RegisterPage/>,
            }
        ]
    },
]);
const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <AxiosProvider>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={customTheme}>
                        <SnackbarProvider maxSnack={4} autoHideDuration={5000}>
                            <ConfirmationModalProvider>
                                <AuthProvider>
                                    <ChapterEditorProvider>
                                        <RouterProvider router={router}/>
                                        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
                                    </ChapterEditorProvider>
                                </AuthProvider>
                            </ConfirmationModalProvider>
                        </SnackbarProvider>
                    </ThemeProvider>
                </QueryClientProvider>
            </AxiosProvider>
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
