import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
    Box, Button,
    Container,
    LinearProgress, LinearProgressProps,
    Typography
} from '@mui/material';
import {completeChapter, fetchChapterById, getChapterPages} from "../../api/chapter";
import {useNavigate, useParams} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {Check} from "@mui/icons-material";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {AuthContext} from "../../contexts/AuthContext";
import {useSnackbar} from "notistack";
import PageList from "./PageList";
import {ChapterReaderProvider} from "./ChapterReaderContext";
import {Page} from "../../types/pages";
import Confetti from "react-confetti";

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

const ChapterReader = () => {
    const params = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const dimensions = getWindowDimensions();
    const {enqueueSnackbar} = useSnackbar();

    const {user} = useContext(AuthContext);
    const comicPagesQuery = useQuery(['chapter-pages', params.chapterId], () => getChapterPages(params.chapterId), {
        enabled: false,
        staleTime: Infinity,
        onSuccess: (data) => {
            if (allPages.length > 0) return;
            initializePages(data);
        }
    });
    const chapterQuery = useQuery(['chapter', params.chapterId], () => fetchChapterById(params.chapterId), {
        enabled: false,
        staleTime: Infinity
    });
    const [allPages, setAllPages] = useState<Page[]>([]);
    const [showConfetti, setShowConfetti] = useState(false);

    const [completedPageCount, setCompletedPageCount] = useState<number>(0);
    const [maxSolvablePages, setMaxSolvablePages] = useState<number>(0);

    const completedPercentage = useMemo(() => {
        return (completedPageCount / maxSolvablePages) * 100;
    }, [completedPageCount, maxSolvablePages]);

    const completeChapterMutation = useMutation(completeChapter, {
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: 'chapters'});
            chapterQuery.refetch();

            if (data.result?.difficultyUnlocked) {
                navigate("/", {state: {showConfetti: true, difficultyUnlocked: data.result.difficultyUnlocked}});
                return;
            }

            navigate("/", {state: {showConfetti: true}});
        },
        onError: (error) => {
            console.error(error);
            enqueueSnackbar("There was a problem completing this chapter", {variant: "error"});
        }
    });

    const initializePages = (pages: Page[]) => {
        setAllPages(pages);
        setMaxSolvablePages(pages.filter(page => page.quiz || page.puzzle).length);
        setCompletedPageCount(pages.filter(page => page.completed).length);
    }

    useEffect(() => {
        comicPagesQuery.refetch();
        chapterQuery.refetch();
    }, [])

    if (comicPagesQuery.status === "loading" || chapterQuery.status === "loading") {
        return (
            <Container>
                <Box sx={{padding: 5}}>
                    <Typography variant="h5" component="div" className="chapterTitle">
                        Loading Chapter...
                    </Typography>
                    <LinearProgress/>
                </Box>
            </Container>
        )
    }

    if (comicPagesQuery.data === undefined || comicPagesQuery.data.length === 0) {
        return (
            <Container>
                <Box sx={{padding: 5}}>
                    <Typography variant="h5" component="div" className="chapterTitle">
                        No pages found for this chapter.
                    </Typography>
                </Box>
            </Container>
        )
    }


    const solvePage = (pageId: number) => {
        setShowConfetti(true);
        if (!user) return;

        setCompletedPageCount(prevCount => prevCount + 1);
    }

    const markChapterAsComplete = () => {
        if (user) completeChapterMutation.mutate(parseInt(params.chapterId));
    }

    const showCompleteButton = () => user && (completedPercentage >= 99 || maxSolvablePages === 0);

    return (
        <ChapterReaderProvider onSolvePage={solvePage}>
            <>
                {showConfetti &&
                    <Confetti
                        width={dimensions.width}
                        height={dimensions.height}
                        recycle={false}
                        onConfettiComplete={() => setShowConfetti(false)}
                    />
                }
                <Container>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        maxHeight: '88vh',
                    }}>
                        <Box sx={{padding: 1, overflowY: "scroll", mb: 2, flexGrow: 1}} textAlign="center">
                            <PageList pages={allPages} onSolvePage={() => {}}/>

                            <CompleteChapterButton showButton={() => showCompleteButton()} isCompleted={chapterQuery.data?.completed}
                                                   markChapterAsComplete={markChapterAsComplete}/>
                        </Box>
                        <ProgressBar value={completedPercentage} loggedIn={user}
                                     chapterCompleted={chapterQuery.data?.completed}/>
                    </Box>
                </Container>
            </>
        </ChapterReaderProvider>
    );
}

interface CompleteChapterButtonProps {
    showButton: () => boolean;
    isCompleted: boolean;
    markChapterAsComplete: () => void;
}
const CompleteChapterButton = (props: CompleteChapterButtonProps) => {
    if (!props.showButton()) return null;

    return (
        <Box sx={{mt: 2, mb: 10}}>
            <Typography sx={{marginBottom: 2}} variant="subtitle1" component="div">
                Congratulations! You've completed all the puzzles and quizzes in this chapter.
            </Typography>
            <Button disabled={props.isCompleted} variant="contained"
                    onClick={() => props.markChapterAsComplete()}>
                {props.isCompleted ? "Completed" : "Complete Chapter"}
            </Button>
        </Box>
    )
}

const ProgressBar = ({value, loggedIn, chapterCompleted = false}) => {
    const theme = useTheme();

    const WrapperBox = ({children}) => (
        <Box sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            borderColor: theme.palette.text.secondary,
            borderTop: 2,
            paddingTop: 0.5,
            paddingBottom: 1,
        }}>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                {children}
            </Box>
        </Box>
    );

    if (!loggedIn) {
        return (
            <WrapperBox>
                <Typography variant="subtitle2" color={theme.palette.text.disabled}>
                    Log in to track your progress and unlock T-Force members for Soccer Shootout!
                </Typography>
            </WrapperBox>
        )
    }

    return (
        <WrapperBox>
            <Typography variant="subtitle1" sx={{marginRight: 1, marginLeft: 2}} align={"center"}>
                Progress
            </Typography>
            <LinearProgressWithLabel variant="determinate" value={value} chapterCompleted={chapterCompleted}/>
        </WrapperBox>
    )
}

function LinearProgressWithLabel(props: LinearProgressProps & {
    value: number, chapterCompleted
        :
        boolean
}) {
    const cleanedProps = {...props};
    delete cleanedProps.chapterCompleted;

    return (
        <Box sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" {...cleanedProps} />
            </Box>
            <Box sx={{display: "flex", alignContent: "center", minWidth: 35}}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>

                <Check color={"success"} sx={{
                    visibility: props.chapterCompleted ? "visible" : "hidden",
                    marginX: 1
                }}/>
            </Box>
        </Box>
    );
}

export default ChapterReader;
