import React, {useContext} from 'react';
import {
    Button,
    TextField,
    Paper,
    Typography,
    Container,
    Fade,
    Alert,
    Collapse,
    InputAdornment, IconButton, FormControlLabel, Checkbox
} from '@mui/material';
import {Link, useNavigate} from "react-router-dom";
import {Box} from "@mui/system";
import {AuthContext} from "../../contexts/AuthContext";
import {useForm} from "react-hook-form";
import {enqueueSnackbar} from "notistack";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const Row = ({children, ...props}) => <Box {...props}
                                           sx={{
                                               display: "flex",
                                               justifyContent: "center",
                                               alignItems: "center",
                                               mt: 2
                                           }}>
    {children}
</Box>

const Login = () => {
    const navigate = useNavigate();
    const {login} = useContext(AuthContext);
    const {register, handleSubmit, formState: {errors}, setError, clearErrors} = useForm();
    const [loginError, setLoginError] = React.useState<string>(null);
    const [showPassword, setShowPassword] = React.useState(false);
    const [rememberMe, setRememberMe] = React.useState(false);

    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked);
    };

    const onSubmit = async ({username, password}) => {
        try {
            clearErrors();
            await login(username, password, rememberMe);
            enqueueSnackbar("Successfully logged in to your account. Welcome to the Aero Decoder!", {variant: "success"});
            navigate('/');
        } catch (error) {
            if (error.response.status === 401) {
                if (error.response.data.email) {
                    navigate('/verify-email', {state: {email: error.response.data.email}});
                }

                setError("username", {type: "manual"});
                setError("password", {type: "manual"});
                setLoginError(error.response.data.message);
            }
        }
    }

    return (
        <Container sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Fade in={true} timeout={500}>
                <Paper sx={{
                    padding: {
                        xs: 2, // On extra small screens, the padding is 2
                        sm: 3, // On small screens, the padding is 3
                        md: 4, // On medium screens, the padding is 4
                        lg: 5, // On large screens, the padding is 5
                    },
                    width: {
                        xs: "100vw",
                        md: "50vw",
                        lg: "30vw",
                    },
                    margin: {
                        xs: 0,
                        sm: 5,
                    }
                }}>
                    <Box sx={{mb: 2}}>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                    </Box>
                    <Collapse in={loginError !== null} mountOnEnter unmountOnExit>
                        <Alert sx={{mb: 2}} severity="error">{loginError}</Alert>
                    </Collapse>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <TextField
                                placeholder="Username"
                                label={"Username"}
                                fullWidth
                                name="username"
                                variant="outlined"
                                {...register("username", {required: "Username is required."})}
                                error={!!errors.username}
                                required
                                autoFocus
                            />
                        </Row>
                        <Row>
                            <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
                                <TextField
                                    type={showPassword ? "text" : "password"}
                                    label={"Password"}
                                    placeholder="Password"
                                    fullWidth
                                    name="password"
                                    variant="outlined"
                                    {...register("password", {required: "Password is required."})}
                                    error={!!errors.password}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                        </Row>
                        <Box sx={{display: "flex", mt: 1}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rememberMe}
                                        onChange={handleRememberMeChange}
                                        name="rememberMe"
                                        color="primary"
                                    />
                                }
                                label="Remember me"
                            />
                            <Box sx={{flexGrow: 1}}></Box>
                            <Button variant={"text"} color="primary" onClick={() => navigate("/forgot-password")}>
                                I Forgot My Password
                            </Button>
                        </Box>
                        <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 2}}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Box>
                    </form>
                    <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mt: 2}}>
                        <Typography variant="caption">
                            You can also sign in with your email address
                        </Typography>

                        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography variant="body2">
                                Don't have an account?
                            </Typography>
                            <Link to={"/register"}>
                                <Button color="primary">
                                    Register
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Paper>
            </Fade>
        </Container>
    );
};

export default Login;
