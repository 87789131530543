import {Page} from "../../types/pages";
import {Grid, Typography} from "@mui/material";
import React, {useCallback, useContext, useEffect, useMemo} from "react";
import PageView from "./PageView";
import InteractivePageView from "./InteractivePageView";
import {ChapterReaderContext} from "./ChapterReaderContext";
import {Quiz} from "../../types/quiz";
import {PageModalProvider} from "./PageModalContext";

interface PageListProps {
    pages: Page[];
    onSolvePage: (pageId: number) => void;
}

const isPageInteractive = (page: Page) => {
    return page?.quiz || page?.puzzle
}

const PageList = React.memo((props: PageListProps) => {
    const [allPages, setAllPages] = React.useState<Page[]>([]);
    const {quizAnswerOrders, setQuizAnswerOrder} = useContext(ChapterReaderContext);

    useEffect(() => {
        if (props.pages === undefined) return;

        setAllPages(props.pages);
        initQuizAnswerOrder(props.pages);
    }, [props.pages]);

    const getRandomizedAnswers = useMemo(() => (quiz: Quiz) => {
        if (!quizAnswerOrders[quiz.id]) {
            const randomizedAnswers = [quiz.answer, ...quiz.distractions].sort(() => Math.random() - 0.5);
            setQuizAnswerOrder(quiz.id, randomizedAnswers);
        }
        return quiz;
    }, [quizAnswerOrders, setQuizAnswerOrder]);

    const initQuizAnswerOrder = useCallback((pages: Page[]) => {
        pages.filter(page => page.quiz).forEach(page => getRandomizedAnswers(page.quiz));
    }, [getRandomizedAnswers]);

    return (
        <>
            <Typography variant="h4" component="div" className="chapterTitle" align={"left"} sx={{marginTop: 3}}>
                DECODING ASTHMA GRAPHIC NOVEL
            </Typography>
            <Grid container rowSpacing={3}>
                {allPages.map((page, index) => (
                    isPageInteractive(page) ? (
                            <InteractivePageView key={index} page={page} onSolvePage={props.onSolvePage}/>
                        ) : (
                            <PageModalProvider key={index}>
                                <PageView page={page} altText={`Page ${index + 1}`}/>
                            </PageModalProvider>
                        )
                ))}
            </Grid>
            <Typography variant="h4" component="div" className="chapterTitle" align={"center"} sx={{marginTop: 3}}>
                END OF CHAPTER
            </Typography>
        </>
    )
}, (prevProps, nextProps) => prevProps.pages === nextProps.pages);


export default PageList;
