import {Box, Button, Container, Grid, IconButton, Typography} from "@mui/material";
import React, {useContext} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import {useTheme} from "@mui/material/styles";
import {EditOutlined} from "@mui/icons-material";
import {getUserStats, sendResetRequest} from "../../api/auth";
import {useMutation, useQuery} from "react-query";
import {UserStats} from "../../types/user-stats";
import {AxiosError} from "axios";
import {useSnackbar} from "notistack";

const AccountPage = () => {
    const { user } = useContext(AuthContext);
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();

    const statsQuery = useQuery('userStats', getUserStats);
    const resetMutation = useMutation(sendResetRequest, {
        onSuccess: () => {
            enqueueSnackbar("Reset request sent. Check your email for further instructions.", {variant: "success"});
        },
        onError: (error: AxiosError) => {
            console.error(error);
            // @ts-ignore
            const message = error.response.data ? error.response.data.message : "An error occurred.";
            enqueueSnackbar("There was a problem: " + message, {variant: "error"});
        }
    });

    if (!user) {
        return (
            <Container maxWidth="md">
                <Typography variant="h4" align="center" style={{ margin: '20px 0' }}>Account</Typography>
                <Typography variant="body1" align="center">You must be logged in to view this page.</Typography>
            </Container>
        )
    }

    const Field = ({label, value, onClick}) => {
        return (
            <Box sx={{display: "flex", flexDirection: "column", marginY: 1}}>
                <Typography variant="subtitle1">{label}</Typography>
                <Box sx={{display: "flex", justifyContent: "space-between", padding: 0.5, alignContent: "center", backgroundColor: theme.palette.grey.A100, borderRadius: 2}}>
                    <Typography sx={{marginTop: 0.3, marginLeft: 1}} variant="body1">{value}</Typography>
                    <IconButton onClick={onClick} size={"small"}>
                        <EditOutlined />
                    </IconButton>
                </Box>
            </Box>
        )
    }

    const Statistic = ({label, value}) => {
        return (
            <Box sx={{display: "flex", flexDirection: "row", marginY: 1}}>
                <Typography variant="subtitle1" fontWeight={"bold"}>{label} - </Typography>
                <Typography sx={{marginTop: 0.3, marginLeft: 1}} variant="body1">{value}</Typography>
            </Box>
        )
    }

    const StatisticsPanel = ({stats}: {stats: UserStats}) => {
        const getChaptersRead = () => {
            const total = stats.totalChaptersRead;
            const allCount = stats.chapterCount;
            if (allCount === 0) {
                return "0/0 (0%)";
            }

            const percent = Math.round((total / allCount) * 100);

            return `${total}/${allCount} (${percent}%)`;
        }

        return (
            <Box sx={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                <Statistic label={"Total Chapters Read"} value={getChaptersRead()} />
                <Statistic label={"Total Games of Soccer Shootout Played"} value={stats.totalGamesPlayed} />
                <Statistic label={"Highest Leaderboard Score"} value={stats.highestScore + " (Worldwide)"} />
            </Box>
        )
    }

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" style={{ margin: '20px 0' }}>Account Details</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" style={{ margin: '20px 0' }}>Profile Details</Typography>
                    <Box sx={{display: "flex", flexDirection: "column", justifyContent: "flex-start", paddingRight: 2}}>
                        <Field label="Username" value={user.username} onClick={() => resetMutation.mutate({type: "username-reset"})} />
                        <Field label="Email Address" value={user.email} onClick={() => resetMutation.mutate({type: "email-reset"})} />
                        <Field label="Password" value={"*********"} onClick={() => resetMutation.mutate({type: "password-reset"})} />
                        <Button sx={{marginTop: 5}} onClick={() => console.log("todo")} disabled={true} variant={"outlined"} color={"warning"}>Delete Account</Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant="h6" style={{ margin: '20px 0' }}>Profile Statistics</Typography>
                    {statsQuery.isLoading ? <Typography>Loading...</Typography> : <StatisticsPanel stats={statsQuery.data} />}
                </Grid>
            </Grid>
        </Container>
    )
}

export default AccountPage;
