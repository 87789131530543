/** @format */

import React, { useContext, useEffect } from 'react';
import { Box } from '@mui/system';
import Phaser from 'phaser';
import { Container, Typography } from '@mui/material';
import PlayScene from './PlayScene';
import gameConstants from './game-constants';
import GameOverScene from './GameOverScene';
import MenuScene from './MenuScene';
import { AuthContext } from '../../../contexts/AuthContext';
import HelpScene from './HelpScene';
import DifficultyScene from './DifficultyScene';

export const EventBus = new Phaser.Events.EventEmitter();

const Game = () => {
    const { user } = useContext(AuthContext);
    let game: Phaser.Game | null = null;

    useEffect(() => {
        if (!user) return;

        createGame();

        return () => {
            game.destroy(true);
        };
    }, [user]);

    const createGame = () => {
        const config = {
            type: Phaser.AUTO,
            autoCenter: Phaser.Scale.CENTER_BOTH,
            scale: {
                mode: Phaser.Scale.FIT,
                width: 1080,
                height: 1800,
                min: {
                    width: gameConstants.screenBaseSize.minWidth,
                    height: gameConstants.screenBaseSize.minHeight,
                },
                max: {
                    width: gameConstants.screenBaseSize.maxWidth,
                    height: gameConstants.screenBaseSize.maxHeight,
                },
            },
            parent: 'phaser-game',
            physics: {
                default: 'arcade',
            },
            scene: [MenuScene, DifficultyScene, HelpScene, PlayScene, GameOverScene],
        };

        game = new Phaser.Game(config);

        // @ts-ignore
        game.userId = user.id;
    };

    return (
        <Container maxWidth="md">
            <Box sx={{ width: '100%', height: '80vh', marginY: 1 }}>
                {!user && <Typography>Loading user data... Please log in if you haven't already.</Typography>}
                <div id="phaser-game"></div>
            </Box>
        </Container>
    );
};

export default Game;
