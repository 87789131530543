import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import {FormControl, Select, SelectChangeEvent} from "@mui/material";

export type PageType = "comic" | "puzzle" | "quiz";

export interface PageTypeDropdownProps {
    pageTypeSelected: PageType;
    onPageTypeSelect: (event: SelectChangeEvent) => void;
}

const PageTypeDropdown: React.FC<PageTypeDropdownProps> = ({pageTypeSelected, onPageTypeSelect}) => (
    <FormControl sx={{minWidth: 160, marginRight: 1}} size="small">
        <Select
            labelId="page-type-select-label"
            id="page-type-select"
            value={pageTypeSelected}
            onChange={onPageTypeSelect}
            displayEmpty
        >
            <MenuItem value={"comic"}>Comic Strip</MenuItem>
            <MenuItem value={"puzzle"}>Puzzle</MenuItem>
            <MenuItem value={"quiz"}>Quiz</MenuItem>
        </Select>
    </FormControl>
);

export default PageTypeDropdown;
