import React, {useContext} from 'react';
import {
    Button,
    TextField,
    Box,
    Typography,
    Container,
    Fade,
    Paper,
    InputAdornment,
    IconButton
} from '@mui/material';
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import {useForm} from "react-hook-form";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useSnackbar} from "notistack";

const RegisterPage = () => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const {register: registerUser} = useContext(AuthContext);
    const { register, handleSubmit, formState: { errors }, setError, clearErrors, getValues } = useForm();
    const [showPassword, setShowPassword] = React.useState(false);

    const onSubmit = async ({email, username, password}) => {
        try {
            clearErrors();
            await registerUser(email, username, password);
            enqueueSnackbar("Registered your account successfully.", {variant: "success"});
            navigate('/verify-email', {state: {email: email}});
        } catch (error) {
            if (error.response.status === 409) {
                const message = error.response.data.message;
                if (message.includes("Email")) {
                    setError("email", { type: "manual", message });
                } else if (message.includes("Username")) {
                    setError("username", { type: "manual", message });
                }
            } else if (error.response.status === 422) {
                const message = error.response.data.message;
                if (message.includes("Password")) {
                    setError("password", { type: "manual", message });
                } else if (message.includes("Username")) {
                    setError("username", { type: "manual", message });
                }
            } else {
                enqueueSnackbar("Sorry, there was a problem registering your account.", {variant: "error"});
            }
        }
    }

    return (
        <Container sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Fade in={true} timeout={500}>
                <Paper sx={{
                    padding: {
                        xs: 2,
                        sm: 3,
                        md: 4,
                        lg: 5,
                    },
                    width: {
                        xs: "100vw",
                        md: "50vw",
                        lg: "30vw",
                    },
                    margin: {
                        xs: 0,
                        sm: 5,
                    }
                }}>
                    <Box sx={{mb: 2}}>
                        <Typography component="h1" variant="h5">
                            Register
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", mt: 2}}>
                            <TextField
                                label={"Email"}
                                fullWidth
                                name="email"
                                variant="outlined"
                                {...register("email", {required: "Email is required.", pattern: {value: /^\S+@\S+$/i, message: "Must be a valid email address."}})}
                                error={!!errors.email}
                                helperText={errors.email?.message.toString()}
                                required
                                autoFocus
                            />
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", mt: 2}}>
                            <TextField
                                label={"Username"}
                                fullWidth
                                name="username"
                                variant="outlined"
                                {...register("username", {required: "Username is required.", minLength: {value: 5, message: "Username must be at least 5 characters long"}})}
                                error={!!errors.username}
                                helperText={errors.username?.message.toString()}
                                required
                                autoFocus
                            />
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", mt: 2}}>
                            <TextField
                                type={showPassword ? "text" : "password"}
                                fullWidth
                                label={"Password"}
                                name="password"
                                variant="outlined"
                                {...register("password", {required: "Password is required.", pattern: {value: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+){8,}$/, message: "Password must be at least 8 characters long and contain at least one number"}})}
                                error={!!errors.password}
                                helperText={errors.password?.message.toString()}
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", mt: 2}}>
                            <TextField
                                type={showPassword ? "text" : "password"}
                                label={"Confirm Password"}
                                fullWidth
                                name="confirmPassword"
                                variant="outlined"
                                {...register("confirmPassword", {
                                    required: "Confirm password is required.",
                                    validate: value =>
                                        value === getValues().password || "Passwords do not match."
                                })}
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword?.message.toString()}
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 2}}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Box>
                    </form>
                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", mt: 2}}>
                        <Typography variant="body2">
                            Already have an account?
                        </Typography>
                        <Link to={"/login"}>
                            <Button color="primary">
                                Login
                            </Button>
                        </Link>
                    </Box>
                </Paper>
            </Fade>
        </Container>
    );
};

export default RegisterPage;
