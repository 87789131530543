/** @format */

import React, {useEffect, useState} from 'react';
import {Container, Grid, Grow, styled, Tooltip, tooltipClasses, TooltipProps, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {Link, useLocation} from 'react-router-dom';
import {fetchChapters} from '../../api/chapter';
import {useQuery} from 'react-query';
import {Check} from '@mui/icons-material';
import {useTheme} from '@mui/material/styles';
import {Chapter} from '../../types/chapter';
import NewEnemyPrompt from './NewEnemyPrompt';
import Confetti from 'react-confetti';

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height,
    };
}

function ChapterGrid() {
    const theme = useTheme();
    const location = useLocation();
    const [openNewEnemyPrompt, setOpenNewEnemyPrompt] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const chaptersQuery = useQuery('chapters', () => fetchChapters());
    const dimensions = getWindowDimensions();

    useEffect(() => {
        if (location.state) {
            if (location.state.difficultyUnlocked) setOpenNewEnemyPrompt(true);
            if (location.state.showConfetti) setShowConfetti(true);
        }
    }, [location]);

    const handleClose = () => {
        setOpenNewEnemyPrompt(false);
        location.state = null;
    };

    if (chaptersQuery.isLoading) {
        return (
            <Container>
                <Box
                    sx={{
                        pt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Typography variant="h5" component="div">
                        Loading Chapters
                    </Typography>
                </Box>
            </Container>
        );
    }

    const getTitle = (chapter: Chapter) => {
        return chapter.title + (chapter.completed ? ' (Completed)' : '');
    };

    return (
        <>
            {showConfetti && (
                <Confetti
                    width={dimensions.width}
                    height={dimensions.height}
                    recycle={false}
                    onConfettiComplete={() => setShowConfetti(false)}
                />
            )}
            <NewEnemyPrompt
                isModalOpen={openNewEnemyPrompt}
                onClose={handleClose}
                difficultyLevel={location.state?.difficultyUnlocked}
            />
            <Container>
                <Box
                    sx={{
                        pt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            alignContent: 'center',
                        }}>
                        <Grow in={true} timeout={300}>
                            <img src={'./landingpageimage.jpg'} alt={'Landing Page Image'} style={{width: '100%'}} />
                        </Grow>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginY: 2,
                        }}>
                        <Typography
                            sx={{marginTop: 1}}
                            variant="h3"
                            component="div"
                            color={'secondary'}
                            align={'center'}>
                            START:
                        </Typography>
                        <Typography variant="h5" component="div" align={'center'}>
                            CLICK ON AN ICON
                        </Typography>
                    </Box>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            marginBottom: '25vh',
                        }}>
                        {chaptersQuery.data
                            ? chaptersQuery.data.map((chapter, index) => (
                                  <Grow in={true} timeout={300 + index * 100} key={index}>
                                      <Grid
                                          item
                                          xs={12}
                                          sm={3}
                                          lg={2}
                                          sx={{
                                              flex: '1 1 calc(50% - 16px)',
                                              display: 'flex',
                                              alignItems: 'stretch',
                                          }}>
                                          <BigTooltip title={getTitle(chapter)} followCursor={true}>
                                              <Link
                                                  to={'/chapter/' + chapter.id}
                                                  style={{textDecoration: 'none', width: '100%'}}>
                                                  <Box
                                                      sx={{
                                                          width: '100%',
                                                          justifyContent: 'center',
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          transform: 'translateY(0)',
                                                          transition: 'all 0.15s ease-in-out',
                                                          '&:hover': {
                                                              transform: 'translateY(-4px)',
                                                          },
                                                      }}>
                                                      <img
                                                          src={chapter.coverImageUrl}
                                                          alt={chapter.title}
                                                          style={{
                                                              width: '100%',
                                                              maxWidth: '100%',
                                                              height: 'auto',
                                                              ...(chapter.completed && {
                                                                  filter: 'grayscale(30%)',
                                                                  opacity: '0.9',
                                                              }),
                                                          }}
                                                      />

                                                      {chapter.completed && (
                                                          <Box
                                                              sx={{
                                                                  position: 'absolute',
                                                                  right: 10,
                                                                  bottom: 10,
                                                                  backgroundColor: theme.palette.success.main,
                                                                  borderRadius: 50,
                                                                  display: 'flex',
                                                                  alignItems: 'center',
                                                                  justifyContent: 'center',
                                                                  padding: 0.2,
                                                              }}>
                                                              <Check
                                                                  sx={{
                                                                      fontSize: 32,
                                                                      color: theme.palette.background.default,
                                                                  }}
                                                              />
                                                          </Box>
                                                      )}
                                                  </Box>
                                              </Link>
                                          </BigTooltip>
                                      </Grid>
                                  </Grow>
                              ))
                            : null}
                    </Grid>
                </Box>
            </Container>
        </>
    );
}

const BigTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 18,
    },
}));

export default ChapterGrid;
