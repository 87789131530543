import Phaser from "phaser";
import goalSprite from "./sprites/Goal.png";
import fieldSprite from "./sprites/field.png";
import soccerBallSprite from './sprites/soccerball.png';
import io from "socket.io-client";
import gameConstants from "./game-constants";

export default class MenuScene extends Phaser.Scene {
    soccerball;

    constructor() {
        super('menu')

        this.score = 0;
    }

    init(data) {
        if (data.resetSocket === undefined) {
            this.shouldResetSocket = true;
        } else {
            this.shouldResetSocket = data.resetSocket;
        }
        this.score = data.score || 0;
    }

    preload() {
        this.load.image('goal', goalSprite);
        this.load.image('field', fieldSprite);
        this.load.image('soccerBall', soccerBallSprite);
    }

    create() {
        if (this.shouldResetSocket) {
            if (this.game.socket) this.game.socket.disconnect();

            let socketURL = process.env.REACT_APP_API_URL.replace('/api/v1', '');
            if (!socketURL) console.error("No Socket Connection");

            this.game.socket = io(socketURL);
            this.game.socket.emit('handshake', { userId: this.game.userId })

            this.game.socket.on('handshake', (res) => {
                if (!res.success) {
                    console.error("Handshake failed", res.message);
                    this.loadingText.setText('Failed to connect to server. Please try again later.');
                    return;
                }

                this.game.userSettings = res.settings;
            })

            this.game.socket.on('settings-update', (res) => {
                if (!res.success) {
                    console.error("Settings update failed", res.message);
                    return;
                }

                console.log("Updated settings");
                this.game.userSettings = res.settings;
            });
        }

        const canvHeight = this.sys.canvas.height / 2;
        const canvWidth = this.sys.canvas.width / 2;

        this.bg = this.add.image(canvWidth, canvHeight, 'field').setOrigin(.5).setScale(1);
        this.bg.setDisplaySize(this.sys.canvas.width, this.sys.canvas.height)
        this.bg.preFX.addBlur();

        const soccerBall = this.physics.add.sprite(canvWidth, canvHeight, 'soccerBall');
        soccerBall.preFX.addBlur();

        const { width, height } = this.scale.gameSize
        const { titleStyle, subtitleStyle } = gameConstants.textDecoration;

        const titleText = this.add.text(width * 0.5, height * 0.18, 'Soccer Shootout', titleStyle).setOrigin(0.5)

        this.tweens.add({
            targets: titleText,
            angle: { from: -5, to: 5},
            duration: 2000,
            ease: 'easeInOutSine',
            yoyo: true,
            loop: -1
        })

        this.tweens.add({
            targets: titleText,
            scale: { from: 0.95, to: 1.05},
            duration: 4000,
            ease: 'Power2',
            yoyo: true,
            loop: -1
        })


        this.loadingText = this.add.text(width * 0.5, height * 0.4, 'Loading...', subtitleStyle).setOrigin(0.5)

        if (!this.shouldResetSocket) {
            this.createMenu();
        } else {
            this.game.socket.on('handshake', (res) => {
                if (!res.success) {
                    console.error("Handshake failed", res.message);
                    this.loadingText.setText('Failed to connect to server. Please try again later.');
                    return;
                }

                this.createMenu();
            });
        }

        // Set the soccer ball's velocity
        soccerBall.setVelocity(600, 600);
        soccerBall.setScale(0.15);

        // Make the soccer ball bounce when it hits the world bounds
        soccerBall.setCollideWorldBounds(true);
        soccerBall.setBounce(1, 1);

        this.soccerball = soccerBall;
    }

    createMenu() {
        this.loadingText.destroy();

        this.createMenuButton('Play', () => this.scene.start('game'), 0.72, '#a5ff59', 113);
        this.createMenuButton('Select Difficulty', () => this.scene.start('difficulty'), 0.8, '#fff'); //this.scene.start('difficulty')
        this.createMenuButton('How to Play', () => this.scene.start('help'), 0.85, '#ffcf33');
    }

    createMenuButton(text, onClick, y, color, size = 51) {
        const { buttonStyle } = gameConstants.textDecoration;
        const { width, height } = this.scale.gameSize
        const newButton = this.add.text(width * 0.5, height * y, text, {...buttonStyle, fill: color, fontSize: size}).setOrigin(0.5).setInteractive();
        newButton.on('pointerdown', () => onClick());
        newButton.on('pointerover', () => startHover());
        newButton.on('pointerout', () => stopHover());

        const startHover = () => {
            this.tweens.add({
                targets: newButton,
                scale: { from: 1, to: 1.1},
                duration: 450,
                ease: 'Power2',
                yoyo: true,
                loop: -1
            })
        }

        const stopHover = () => {
            this.tweens.killTweensOf(newButton);
            this.tweens.add({
                targets: newButton,
                scale: 1,
                duration: 300,
                ease: 'Power2',
                onComplete: () => this.tweens.killTweensOf(newButton)
            })
        }
    }

    update() {
        this.soccerball.angle += 6;
    }
}
