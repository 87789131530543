import {Badge, Box, Button, Card, CardMedia, Typography} from "@mui/material";
import React, {useContext} from "react";
import {Image} from "@mui/icons-material";
import {ChapterEditorContext} from "../../contexts/ChapterEditorContext";
import {useTheme} from "@mui/material/styles";

interface ImageSelectFieldProps {
    onImageChange: (url: string, key?: string) => void;
    label: string;
    imageUrl: string;
    size?: "sm" | "md" | "lg";
    showModified?: boolean;
    showPreview?: boolean;
}

const ImageSelectField = ({onImageChange, label, imageUrl, size, showModified, showPreview = true}: ImageSelectFieldProps) => {
    // @ts-ignore
    const {openSidebar, closeSidebar, imageSlotData} = useContext(ChapterEditorContext);
    const theme = useTheme();

    const handleOnImageClick = (url) => {
        onImageChange(url);
        closeSidebar();
    }

    const getHeight = () => {
        if (!size) return "200px";
        if (size === "sm") return "200px";
        if (size === "md") return "400px";
    }

    if (!showPreview) {
        return (
            <Button component="label" variant={"text"} startIcon={<Image/>}
                    onClick={() => openSidebar(label, handleOnImageClick)}>
                Select Cover Photo
            </Button>
        )
    }

    return (
        <Box sx={{display: "flex", paddingBottom: 2}}>
            <Badge color={"warning"} badgeContent={"M"} invisible={!showModified} sx={{mb: 1}}>
                {showPreview && (
            <Card variant={"outlined"}
                  sx={{
                      outline: `solid ${theme.palette.primary.main}`,
                      outlineWidth: imageSlotData.label === label ? "3px" : "0px"
                  }}>
                    <CardMedia
                        component="img"
                        image={imageUrl ? imageUrl : ""}
                        style={{
                            height: getHeight(),
                            width: 'auto',
                            minWidth: '150px',
                            maxWidth: '100%',
                            objectFit: 'contain',
                        }}
                    />
            </Card>
                    )}
            </Badge>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                alignItems: "flex-start",
                padding: 2,
            }}>
                {showPreview && (
                    <Typography variant="h6" gutterBottom>
                        {label}
                    </Typography>
                )}
                <Button component="label" variant={"text"} startIcon={<Image/>}
                        onClick={() => openSidebar(label, handleOnImageClick)}>
                    Select From Uploads
                </Button>
            </Box>
        </Box>
    );
}

export default ImageSelectField;
