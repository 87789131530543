import React, {ChangeEvent} from 'react';
import {
    Box,
    Button,
    Container,
    FormGroup,
    IconButton,
    Modal,
    Paper,
    SelectChangeEvent,
    TextField,
    Typography
} from '@mui/material';
import PageTypeDropdown, {PageType} from "./PageTypeDropdown";
import '../ChapterEditor.css'
import {Page} from "../../../types/pages";
import {Puzzle} from "../../../types/puzzle";
import {PuzzlePanel} from "../../../types/puzzle-panel";
import {Quiz} from "../../../types/quiz";
import {Add, Delete} from "@mui/icons-material";
import ImageSelectField from "../ImageSelectField";
import Sidebar from "../sidebar/SideBar";

interface AddPageModalProps {
    isModalOpen: boolean;
    chapterId: number;
    onClose: () => void;
    addPage: (page: Page) => void;
    pageTypeSelected: PageType;
    onPageTypeSelect: (event: SelectChangeEvent) => void;
}

type ModalState = {
    page: Page;
    setPage: (page: Page) => void;
    puzzle: Puzzle;
    setPuzzle: (puzzle: Puzzle) => void;
    quiz: Quiz;
    setQuiz: (quiz: Quiz) => void;
}

interface FieldsProps {
    state: ModalState;
}


const AddPageModal: React.FC<AddPageModalProps> = ({
                                                       isModalOpen,
                                                       chapterId,
                                                       onClose,
                                                       addPage,
                                                       pageTypeSelected,
                                                       onPageTypeSelect
                                                   }) => {
    const defaultPage: Page = {id: -1, chapterId: chapterId, sequence: 0, completed: false};
    const defaultPuzzle: Puzzle = {
        id: -1,
        title: "",
        description: "",
        chapterId: chapterId,
        imageUrl: "",
        puzzlePanels: [
            {id: -1, imageUrl: "", isCorrectAnswer: true},
            {id: -1, imageUrl: "", isCorrectAnswer: false},
            {id: -1, imageUrl: "", isCorrectAnswer: false},
        ],
    };
    const defaultQuiz: Quiz = {
        id: -1,
        chapterId: chapterId,
        question: "",
        answer: "",
        distractions: [
            ""
        ],
    }

    const [newPage, setNewPage] = React.useState<Page>(defaultPage);
    const [puzzle, setPuzzle] = React.useState<Puzzle>(defaultPuzzle);
    const [quiz, setQuiz] = React.useState<Quiz>(defaultQuiz);

    const state: ModalState = {
        page: newPage,
        setPage: setNewPage,
        puzzle: puzzle,
        setPuzzle: setPuzzle,
        quiz: quiz,
        setQuiz: setQuiz,
    }

    const addNewPage = () => {
        let _newPage = defaultPage;
        if (pageTypeSelected === "comic") {
            _newPage = {...newPage, imageUrl: newPage.imageUrl};
        } else if (pageTypeSelected === "puzzle") {
            _newPage = {...newPage, puzzle: puzzle};
        } else {
            _newPage = {...newPage, quiz: quiz};
        }

        addPage(_newPage);
    }

    const resetState = () => {
        setNewPage(defaultPage);
        setPuzzle(defaultPuzzle);
        setQuiz(defaultQuiz);
    }

    const handleOnPageTypeSelect = (event: SelectChangeEvent) => {
        resetState();
        onPageTypeSelect(event);
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={onClose}
            aria-labelledby="add-page-modal"
            aria-describedby="add-page-modal-description"
        >
            <Container sx={{
                width: "75vw",
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Paper sx={{padding: 2}}>
                    <Typography variant="h6" id="modal-title">
                        Add Page
                    </Typography>
                    <Box sx={{display: 'flex', maxHeight: "70vh", minHeight: "55vh", overflowX: "auto", padding: 2}}>
                        <Box sx={{width: "100%"}}>
                            <FormGroup>
                                <Box sx={{display: 'flex', alignItems: 'center', paddingBottom: 2}}>
                                    <PageTypeDropdown onPageTypeSelect={handleOnPageTypeSelect}
                                                      pageTypeSelected={pageTypeSelected}/>
                                    <TextField size={"small"} label={"Page #"}
                                               inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}/>
                                </Box>
                                {pageTypeSelected === "comic" ? (
                                    <PageFields state={state}/>
                                ) : pageTypeSelected === "puzzle" ? (
                                    <PuzzleFields state={state}/>
                                ) : (
                                    <QuizFields state={state}/>
                                )}
                            </FormGroup>
                        </Box>
                    </Box>
                    <Box
                        sx={{marginTop: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Button onClick={onClose}>Close</Button>
                        <Button variant={"outlined"} color={"success"} onClick={() => addNewPage()}>Add Page</Button>
                    </Box>
                </Paper>
                <Sidebar/>
            </Container>
        </Modal>
    );
}

const PageFields = ({state}: FieldsProps) => {
    const handleImageChange = (url: string) => {
        state.setPage({...state.page, id: -1, imageUrl: url});
    }

    return (
        <ImageSelectField
            onImageChange={handleImageChange}
            label={"Page Image"}
            imageUrl={state.page.imageUrl}
        />
    );
}

const PuzzleFields = ({state}: FieldsProps) => {

    const modifyPuzzlePanels = (index: number, panel: PuzzlePanel) => {
        const newPuzzlePanels = state.puzzle.puzzlePanels;
        newPuzzlePanels[index] = panel;
        state.setPuzzle({...state.puzzle, puzzlePanels: newPuzzlePanels});
    }

    const handleOnFileChange = (url: string, key: string) => {
        console.log("url: " + url + " key: " + key);
        switch (key) {
            case "cover-panel":
                state.setPuzzle({...state.puzzle, imageUrl: url});
                break;
            case "correct-panel-choice":
                modifyPuzzlePanels(0, {...state.puzzle.puzzlePanels[0], imageUrl: url});
                break;
            case "incorrect-panel-choice-a":
                modifyPuzzlePanels(1, {...state.puzzle.puzzlePanels[1], imageUrl: url});
                break;
            case "incorrect-panel-choice-b":
                modifyPuzzlePanels(2, {...state.puzzle.puzzlePanels[2], imageUrl: url});
                break;
            default:
                break;
        }
    }

    const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        state.setPuzzle({...state.puzzle, title: event.target.value});
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        state.setPuzzle({...state.puzzle, description: event.target.value});
    }

    return (
        <Box sx={{display: "flex"}}>
            <Box sx={{width: "50%", flexDirection: "column"}}>
                <Box sx={{paddingRight: 2}}>
                    <TextField onChange={handleTitleChange} sx={{marginBottom: 2}} name={"title"} label={"Title"}
                               variant={"outlined"} fullWidth/>
                    <TextField onChange={handleDescriptionChange} sx={{marginBottom: 2}} name={"description"}
                               label={"Description"} multiline rows={2} variant={"outlined"} fullWidth/>
                </Box>

                <ImageSelectField
                    onImageChange={(url) => handleOnFileChange(url, "cover-panel")}
                    label={"Cover Panel"}
                    imageUrl={state.puzzle.imageUrl}
                />
            </Box>

            <Box sx={{width: "50%", flexDirection: "column"}}>
                <ImageSelectField
                    onImageChange={(url) => handleOnFileChange(url, "correct-panel-choice")}
                    label={"Correct Panel Choice"}
                    imageUrl={state.puzzle.puzzlePanels[0].imageUrl}
                />

                <ImageSelectField
                    onImageChange={(url) => handleOnFileChange(url, "incorrect-panel-choice-a")}
                    label={"Incorrect Panel Choice A"}
                    imageUrl={state.puzzle.puzzlePanels[1].imageUrl}
                />

                <ImageSelectField
                    onImageChange={(url) => handleOnFileChange(url, "incorrect-panel-choice-b")}
                    label={"Incorrect Panel Choice B"}
                    imageUrl={state.puzzle.puzzlePanels[2].imageUrl}
                />
            </Box>
        </Box>
    );
}


const QuizFields = ({state}: FieldsProps) => {
    const {quiz, setQuiz} = state;

    const addDistraction = () => {
        if (quiz.distractions.length >= 4) return;
        setQuiz({...quiz, distractions: [...quiz.distractions, ""]});
    }

    const modifyDistraction = (index: number, distraction: string) => {
        const newDistractions = quiz.distractions;
        newDistractions[index] = distraction;
        setQuiz({...quiz, distractions: newDistractions});
    }

    const deleteDistraction = (index: number) => {
        const newDistractions = quiz.distractions;
        newDistractions.splice(index, 1);
        setQuiz({...quiz, distractions: newDistractions});
    }

    const handleQuestionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setQuiz({...quiz, question: event.target.value});
    }

    const handleAnswerChange = (event: ChangeEvent<HTMLInputElement>) => {
        setQuiz({...quiz, answer: event.target.value});
    }

    return (
        <Box sx={{display: "flex"}}>
            <Box sx={{width: "50%", flexDirection: "column", paddingRight: 1, gap: 2}}>
                <TextField onChange={handleQuestionChange} sx={{marginBottom: 1}} name={"question"} label={"Question"}
                           multiline rows={2} variant={"outlined"} fullWidth/>
                <TextField onChange={handleAnswerChange} name={"answer"} label={"Answer"} rows={1} variant={"outlined"}
                           fullWidth/>
            </Box>

            <Box sx={{width: "50%", flexDirection: "column", paddingRight: 1}}>
                {quiz.distractions.map((distraction, index) => (
                    <Box sx={{display: "flex", alignItems: "center", gap: 1}} key={index}>
                        <TextField value={distraction} sx={{marginBottom: 1}} name={`distraction-${index}`}
                                   label={`Distraction ${index + 1}`} rows={1}
                                   variant={"outlined"} fullWidth
                                   onChange={(e) => modifyDistraction(index, e.target.value)}/>
                        <IconButton disabled={quiz.distractions.length <= 1} onClick={() => deleteDistraction(index)}>
                            <Delete/>
                        </IconButton>
                    </Box>
                ))}
                <Button disabled={quiz.distractions.length >= 4} onClick={addDistraction} startIcon={<Add/>}>Add
                    Distraction</Button>
            </Box>
        </Box>
    );
}

export default AddPageModal;
