/** @format */

import React, {forwardRef, useContext, useEffect, useState} from 'react';
import {Box, Grow} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useSnackbar} from 'notistack';
import {DragDropContext, Draggable} from 'react-beautiful-dnd';
import {StrictModeDroppable} from '../../shared/StrictModeDroppable';
import {Puzzle} from '../../types/puzzle';
import {InteractivePageViewProps} from './InteractivePageView';
import {ChapterReaderContext} from './ChapterReaderContext';

const BoxRef = forwardRef((props, ref) => <Box ref={ref} {...props} />);

const PuzzleReader = (props: InteractivePageViewProps) => {
    const theme = useTheme();
    const [puzzle, setPuzzle] = useState<Puzzle>(props.page.puzzle);
    const [puzzleSolved, setPuzzleSolved] = useState(false);
    const [panelInSlot, setPanelInSlot] = useState(null);

    const {enqueueSnackbar} = useSnackbar();
    const {solvePage} = useContext(ChapterReaderContext);

    useEffect(() => {
        setPuzzle(props.page.puzzle);
        if (props.page.completed) {
            setPuzzleSolved(true);
            setPanelInSlot(props.page.puzzle.puzzlePanels.find((panel) => panel.isCorrectAnswer).imageUrl);
        }
    }, []);

    const solve = () => {
        setPuzzleSolved(true);
        solvePage(props.page.id);
    };

    const onDragEnd = (result) => {
        const {destination, source, draggableId} = result;

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        // If the panel is dropped on the correct slot, execute the command
        if (destination.droppableId === 'panel-slot') {
            const panel = puzzle.puzzlePanels.find((panel) => panel.id + '-panel' === draggableId);

            const isCorrect = panel.isCorrectAnswer;

            if (isCorrect) {
                setPanelInSlot(panel.imageUrl);
                solve();
            } else {
                enqueueSnackbar("Whoops! That's not quite right. Please try again.", {variant: 'error'});
            }
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: {
                        lg: '35vw',
                        md: '60vw',
                        sm: '100vw',
                    },
                    alignItems: 'center',
                    flexDirection: 'column',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'stretch',
                        justifyContent: 'center',
                        mt: 1,
                        width: '100%',
                    }}>
                    <Grow in={true} timeout={500}>
                        <Box sx={{width: '66%', my: 1}}>
                            <img
                                src={puzzle.imageUrl}
                                alt={'puzzle image'}
                                style={{width: '100%', height: '100%', objectFit: 'contain'}}
                            />
                        </Box>
                    </Grow>
                    <Box
                        sx={{
                            width: '34%',
                            my: 2.1,
                            outline: panelInSlot ? 'none' : 'solid 2px #000000',
                        }}>
                        <StrictModeDroppable droppableId="panel-slot">
                            {(provided) => (
                                // @ts-ignore
                                <BoxRef
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'stretch',
                                    }}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {panelInSlot && (
                                        <Grow in={true} timeout={600}>
                                            <img
                                                src={panelInSlot}
                                                alt={'panel slot'}
                                                style={{width: '100%', objectFit: 'contain'}}
                                            />
                                        </Grow>
                                    )}
                                    {provided.placeholder}
                                </BoxRef>
                            )}
                        </StrictModeDroppable>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mb: 2,
                        flexDirection: 'row',
                        width: '100%',
                    }}>
                    <StrictModeDroppable droppableId="droppable" direction={'horizontal'}>
                        {(provided) => (
                            // @ts-ignore
                            <BoxRef
                                sx={{display: 'flex', justifyContent: 'center'}}
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {puzzle.puzzlePanels.map((panel, index) =>
                                    puzzleSolved && panel.isCorrectAnswer ? null : (
                                        <Draggable
                                            key={panel.id + '-panel'}
                                            draggableId={panel.id + '-panel'}
                                            index={index}
                                            isDragDisabled={puzzleSolved}>
                                            {(provided) => (
                                                // @ts-ignore
                                                <BoxRef
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    tabIndex={0}
                                                    role="button"
                                                    sx={{
                                                        mx: 1,
                                                        outline: `solid ${theme.palette.primary.main}`,
                                                        outlineWidth: '0px',
                                                        transform: 'translateY(0)',
                                                        transition: 'all 0.05s ease-in-out',
                                                        '&:hover': {
                                                            outlineWidth: `2px`,
                                                            transform: 'translateY(-4px)',
                                                        },
                                                        '&:focus': {
                                                            outlineWidth: `2px`,
                                                            transform: 'translateY(-4px)',
                                                        },
                                                    }}>
                                                    <Box sx={{width: '100%'}}>
                                                        <img
                                                            src={panel.imageUrl}
                                                            style={{width: '100%', objectFit: 'contain'}}
                                                        />
                                                    </Box>
                                                </BoxRef>
                                            )}
                                        </Draggable>
                                    ),
                                )}
                                {provided.placeholder}
                            </BoxRef>
                        )}
                    </StrictModeDroppable>
                </Box>
            </Box>
        </DragDropContext>
    );
};

export default PuzzleReader;
