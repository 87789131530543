import {Box, Button, Container, Modal, Paper, Typography, Zoom} from "@mui/material";
import React from "react";
import {enemies} from "../chapter-editor/enemy-unlock-modal/EnemyUnlockModal";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

export interface NewEnemyPromptProps {
    isModalOpen: boolean;
    onClose: () => void;
    difficultyLevel: number;
}


const NewEnemyPrompt = ({isModalOpen, onClose, difficultyLevel}: NewEnemyPromptProps) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const enemyToDisplay = enemies[difficultyLevel - 1];

    if (!enemyToDisplay) {
        return null;
    }

    const onPlayClick = () => {
        navigate('/soccer-shootout/game');
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={onClose}
            aria-labelledby="new-enemy-modal"
            aria-describedby="new-enemy-modal-description"
        >
            <Container sx={{
                width: {
                    xs: '90vw',
                    sm: '75vw',
                    md: '50vw',
                    lg: '40vw'
                },
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Zoom in={isModalOpen}>
                    <Paper sx={{padding: 2}}>
                        <Typography textAlign={"center"} variant="h6" id="modal-title">
                            New T-Force Member Unlocked!
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: "column",
                            overflowX: "auto",
                            padding: 2
                        }}>
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <img src={"/enemy-pictures/" + enemyToDisplay.imgUrl} alt={enemyToDisplay.name}
                                     style={{width: 300, height: 300}}/>
                                <Typography sx={{marginTop: 4}} textAlign={"center"} variant={"body1"}>Click "Play" to
                                    play against this new foe in</Typography>
                                <Typography textAlign={"center"} variant={"body1"} color={theme.palette.primary.main}>Soccer
                                    Shootout!</Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{marginTop: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Button color={"warning"} onClick={onClose}>Close</Button>
                            <Button variant={"contained"} color={"success"} onClick={onPlayClick}>Play!</Button>
                        </Box>
                    </Paper>
                </Zoom>
            </Container>
        </Modal>
    )
}

export default NewEnemyPrompt;
