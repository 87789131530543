/** @format */

import {useMediaQuery, useTheme} from '@mui/material';
import {useLocation} from 'react-router-dom';

export const useLayout = () => {
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const showLogo = (location.pathname === '/' || location.pathname === '/soccer-shootout') && !isMobile; // show logo when on index route
    const isOnSoccerShootout = location.pathname.startsWith('/soccer-shootout');

    return {
        isMobile,
        showLogo,
        isOnSoccerShootout,
    };
};
