import React, {useEffect} from "react";
import {Button, IconButton, List, ListItem, Paper, TextField, Typography} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import {Box} from "@mui/system";
import {DisplayProps} from "./PageDisplay";

function QuizPageDisplay({tmpPage, updateTempState}: DisplayProps) {
    const [quiz, setQuiz] = React.useState(tmpPage.quiz);

    const modifyQuiz = (newQuiz) => {
        updateTempState({...tmpPage, quiz: newQuiz});
    }

    useEffect(() => {
        if (tmpPage.quiz)
            setQuiz(tmpPage.quiz);
    }, [tmpPage]);

    const addDistraction = () => {
        if (quiz.distractions.length >= 4) return;
        modifyQuiz({...quiz, distractions: [...quiz.distractions, ""]});
    }

    const modifyDistraction = (index: number, distraction: string) => {
        const newDistractions = [...quiz.distractions];
        newDistractions[index] = distraction;
        modifyQuiz({...quiz, distractions: newDistractions});
    }

    const deleteDistraction = (index: number) => {
        const newDistractions = [...quiz.distractions];
        newDistractions.splice(index, 1);
        modifyQuiz({...quiz, distractions: newDistractions});
    }

    const handleQuestionChange = (event) => modifyQuiz({...quiz, question: event.target.value});
    const handleAnswerChange = (event) => modifyQuiz({...quiz, answer: event.target.value});

    const QuizPreview = () => {
        return (
            <Paper sx={{padding: 2}}>
                <Typography variant="body2" gutterBottom>
                    (Preview)
                </Typography>
                <Typography variant="h5" gutterBottom>
                    {quiz.question}
                </Typography>
                <List>
                    <ListItem>
                        <Typography variant="body1" gutterBottom>
                            {1 + ". " + quiz.answer}
                        </Typography>
                    </ListItem>
                    {quiz.distractions.map((distraction, index) => (
                        <ListItem key={index}>
                            <Typography style={{whiteSpace: "normal"}} variant="body1" gutterBottom>
                                {(index + 1) + 1 + ". " + distraction}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </Paper>
        )
    }

    if (!quiz) return (<>No quiz</>);

    return (
        <Box sx={{display: "flex", flexGrow: 1, flexDirection: "row", marginBelow: 5, marginTop: 15}}>
            <Box sx={{width: "25%", paddingRight: 2}}>
                <QuizPreview/>
            </Box>

            <Box sx={{width: "70%", display: "flex"}}>
                <Box sx={{width: "30%", flexDirection: "column", paddingRight: 1, gap: 2}}>
                    <TextField onChange={handleQuestionChange} sx={{marginBottom: 1}} name={"question"}
                               label={"Question"}
                               multiline rows={2} value={quiz.question} variant={"outlined"} fullWidth/>
                    <TextField onChange={handleAnswerChange} value={quiz.answer} name={"answer"} label={"Answer"}
                               rows={1}
                               variant={"outlined"}
                               fullWidth/>
                </Box>

                <Box sx={{width: "30%", flexDirection: "column", paddingRight: 1}}>
                    {quiz.distractions.map((distraction, index) => (
                        <Box sx={{display: "flex", alignItems: "center", gap: 1}} key={index}>
                            <TextField value={quiz.distractions[index]} sx={{marginBottom: 1}}
                                       name={`distraction-${index}`}
                                       label={`Distraction ${index + 1}`} rows={1}
                                       variant={"outlined"} fullWidth
                                       onChange={(e) => modifyDistraction(index, e.target.value)}/>
                            <IconButton disabled={quiz.distractions.length <= 1}
                                        onClick={() => deleteDistraction(index)}>
                                <Delete/>
                            </IconButton>
                        </Box>
                    ))}
                    <Button disabled={quiz.distractions.length >= 4} onClick={addDistraction} startIcon={<Add/>}>Add
                        Distraction</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default QuizPageDisplay;
