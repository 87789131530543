/** @format */

import React from 'react';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import {createContext, useContext, useState, useCallback} from 'react';

const ConfirmationModalContext = createContext<ConfirmationModalContextType | undefined>(undefined);

interface ConfirmationModalContextType {
    promptModal: (title: string, content: string, onConfirm: () => void, onDeny?: () => void) => void;
    ConfirmationModal: () => React.JSX.Element;
}

export const useConfirmationModal = () => {
    return useContext(ConfirmationModalContext);
};

export const ConfirmationModalProvider = ({children}) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [confirmCallback, setConfirmCallback] = useState<() => {}>(null);
    const [denyCallback, setDenyCallback] = useState<() => {}>(null);

    const promptModal = (title: string, content: string, onConfirm: () => void, onDeny?: () => void) => {
        setTitle(title);
        setContent(content);
        setConfirmCallback(() => onConfirm);
        if (!onDeny) onDeny = () => {};
        setDenyCallback(() => onDeny);
        setModalOpen(true);
    };

    const confirm = useCallback(() => {
        setModalOpen(false);
        confirmCallback();
    }, [confirmCallback]);

    const deny = useCallback(() => {
        setModalOpen(false);
        denyCallback();
    }, [denyCallback]);

    const ConfirmationModal = () => {
        return (
            <Dialog open={isModalOpen} onClose={() => setModalOpen(false)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>{content}</DialogContent>
                <DialogActions>
                    <Button onClick={deny} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirm} variant={'contained'} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const value = {
        promptModal,
        ConfirmationModal,
    };

    return <ConfirmationModalContext.Provider value={value}>{children}</ConfirmationModalContext.Provider>;
};
