import React, {useEffect} from "react";
import {Puzzle} from "../../../types/puzzle";
import {PuzzlePanel} from "../../../types/puzzle-panel";
import {Box} from "@mui/system";
import {TextField} from "@mui/material";
import ImageSelectField from "../ImageSelectField";
import {DisplayProps} from "./PageDisplay";

function PuzzlePageDisplay({tmpPage, updateTempState}: DisplayProps) {
    const [puzzle, setPuzzle] = React.useState<Puzzle>(tmpPage.puzzle);

    const modifyPuzzle = (newPuzzle) => {
        setPuzzle(newPuzzle);
        updateTempState({...tmpPage, puzzle: newPuzzle});
    }

    useEffect(() => {
        console.log("PuzzlePageDisplay: tmpPage = ", tmpPage);
        if (tmpPage.puzzle)
            setPuzzle(tmpPage.puzzle);
    }, [tmpPage]);

    const handleTitleChange = (event) => {
        modifyPuzzle({...puzzle, title: event.target.value})
    }
    const handleDescriptionChange = (event) => {
        modifyPuzzle({...puzzle, description: event.target.value})
    }

    const handleImageChange = (url: string, panelIndex?: number) => {
        if (panelIndex === undefined) {
            modifyPuzzle({...puzzle, imageUrl: url});
        } else {
            const newPanels = [...puzzle.puzzlePanels];
            newPanels[panelIndex] = {...newPanels[panelIndex], imageUrl: url};
            modifyPuzzle({...puzzle, puzzlePanels: newPanels});
        }

    }

    const getPanelName = (panel: PuzzlePanel) => {
        if (panel.isCorrectAnswer) return "Correct Panel Choice";
        else return "Incorrect Panel Choice";
    }

    if (!tmpPage || !puzzle || !tmpPage.puzzle) return (<>No Puzzle...</>);

    return (
        <>
            <Box sx={{display: "flex", flexGrow: 1, flexDirection: "row", marginBelow: 5}}>
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <TextField value={puzzle.title} onChange={handleTitleChange} sx={{marginBottom: 2}} name={"title"}
                               label={"Title"}
                               variant={"outlined"} fullWidth/>
                    <TextField value={puzzle.description} onChange={handleDescriptionChange} sx={{marginBottom: 2}}
                               name={"description"}
                               label={"Description"} multiline rows={2} variant={"outlined"} fullWidth/>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <ImageSelectField
                            onImageChange={(url) => handleImageChange(url)}
                            label={"Setup Panels"}
                            imageUrl={puzzle.imageUrl}/>
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {tmpPage.puzzle.puzzlePanels
                            .sort((a, b) => {
                                if(a.isCorrectAnswer === b.isCorrectAnswer){  // if both have the same correct answer status
                                    return a.id - b.id;  // sort by id
                                }
                                return a.isCorrectAnswer ? -1 : 1;  // sort by correct answer
                            })
                            .map((panel, index) => (
                                <ImageSelectField
                                    key={index}
                                    onImageChange={(url) => handleImageChange(url, index)}
                                    label={getPanelName(panel)}
                                    imageUrl={puzzle.puzzlePanels[index].imageUrl}
                                />
                            ))}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default PuzzlePageDisplay;
