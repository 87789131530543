import Phaser from "phaser";

class Enemy extends Phaser.Physics.Arcade.Sprite {
    constructor(scene, x, y, enemyData, ball) {
        super(scene, x, y, enemyData.type);

        this.scene = scene;
        this.id = enemyData.id;
        this.type = enemyData.type;

        scene.physics.world.enable(this);
        scene.add.existing(this);

        this.movementTweens = [];
        this.isChasing = false;
        this.isWatchingForBall = false;
        this.lastPosition = null;
        this.ball = ball;

        this.size = 0.12;
        this.setScale(this.size);
        this.setData('type', enemyData.type);
        this.setData('points', enemyData.pointValue);
        this.setVelocity(Phaser.Math.Between(-200, 200), Phaser.Math.Between(-200, 200));
        this.setBounce(1);
        this.setCollideWorldBounds(true);

        let spawnAnimSpeed = Phaser.Math.Between(30, 60);

        this.scene.tweens.add({
            targets: this,
            y: {from: this.y - 4, to: this.y},
            scale: {from: 0.1, to: 0.12},
            duration: 100,
            yoyo: true,
            ease: 'Power2',
        })

        this.scene.tweens.add({
            targets: this,
            scale: {from: this.size - 0.01, to: this.size},
            duration: 500,
            yoyo: true,
            ease: 'Power2',
            loop: -1
        });

        this.scene.tweens.add({
            targets: this,
            x: {from: this.x - 2, to: this.x + 2},
            angle: {from: -3, to: 3},
            duration: spawnAnimSpeed,
            yoyo: true,
            loop: 1,
            onComplete: () => {
                this.setupMovement();
            }
        });
    }

    setupMovement() {
        let movementPolarity = Phaser.Math.Between(1, 0) === 0 ? -1 : 1;
        let movementVariance = Phaser.Math.Between(-50, 50);
        let movementValue = (150 + movementVariance) * movementPolarity;

        let animSpeed = 300;

        switch (this.type) {
            case "weather": // Left to Right movement (weather)
                this.applyXMovement(movementValue);
                break;

            case "pollen": // Forward and Backwards Movement (pollen)
                this.applyYMovement(movementValue);
                break;

            case "strong smells": // Left to Right and Forwards and Backwards movement (strong smells)
                this.applyXMovement(movementValue, 2500 - (movementVariance * 3));
                this.applyYMovement(movementValue, 3500 + (movementVariance * 5));
                break;

            case "mold": // Same previous movement with pauses to be stationary (mold)
                this.applyXMovement(movementValue, 2500 - (movementVariance * 3));
                this.applyYMovement(movementValue, 3500 + (movementVariance * 5));
                this.applyMovementWithPauses(movementValue, 5000, 10000, 1000, 3000);
                break;

            case "emotion": // Same Previous Movement but chases the soccer ball (emotion)
                this.isWatchingForBall = true;
                this.applyXMovement(movementValue, 2500 - (movementVariance * 3));
                this.applyYMovement(movementValue, 3500 + (movementVariance * 5));
                break;

            case "dust mites": // Same Previous Movement but faster (dust mites)
                this.isWatchingForBall = true;
                this.applyXMovement(movementValue, 1000 - (movementVariance * 3));
                this.applyYMovement(movementValue, 1500 + (movementVariance * 5));
                animSpeed = 200;
                break;

            default:
                break;
        }

        this.scene.time.delayedCall(Phaser.Math.Between(300, 700), () => {

            let movingAnimation = this.scene.tweens.add({
                targets: this,
                angle: {from: -3, to: 3},
                duration: animSpeed,
                ease: 'easeInOutCubic',
                yoyo: true,
                loop: -1
            });

            this.movementTweens.push(movingAnimation);
        });
    }

    applyMovementWithPauses(minTimeToPause, maxTimeToPause, minPauseDuration, maxPauseDuration) {
        // Define a function to pause and resume movement
        const pauseAndResume = (scene) => {
            const pauseDuration = Phaser.Math.Between(minPauseDuration, maxPauseDuration);
            // Pause the enemy movement
            this.pauseMovement();

            // Resume movement after pauseDuration
            this.currentPauseCall = scene.time.delayedCall(pauseDuration, () => {
                this.resumeMovement();
            });
        };

        // Set up an interval for pausing and resuming movement
        pauseAndResume(this.scene); // Call once to start the cycle
        this.currentPauseCall = this.scene.time.addEvent({
            delay: Phaser.Math.Between(minTimeToPause, maxTimeToPause), // Total duration of one cycle
            callback: pauseAndResume(this.scene),
            callbackScope: this,
            loop: true
        });
    }

    pauseMovement() {
        for (let tween of this.movementTweens) {
            tween.pause();
        }
    }

    resumeMovement() {
        for (let tween of this.movementTweens) {
            tween.resume();
        }
    }

    clampToWorldBounds(movementValue) {
        let newMovementValue = movementValue;

        let min = (this.displayWidth / 1.5);
        newMovementValue = Math.max(movementValue, min);

        let max = this.scene.wallBounds - (this.displayWidth / 1.5);
        newMovementValue = Math.min(movementValue, max);

        return newMovementValue;
    }

    applyYMovement(movementValue, duration) {
        let playerY = this.scene.player.y + (this.scene.player.displayHeight);
        let newY = this.y + movementValue;

        // If the new Y position is greater than the player's Y position, clamp it to the player's Y position
        if (newY > playerY) {
            newY = playerY;
        }

        let tween = this.scene.tweens.add({
            targets: this,
            y: newY,
            duration: duration || 3000,
            yoyo: true,
            repeat: -1,
        });

        this.movementTweens.push(tween);
    }

    applyXMovement(movementValue, duration) {
        let tween = this.scene.tweens.add({
            targets: this,
            x: this.clampToWorldBounds(this.x + movementValue),
            duration: duration || 3000,
            yoyo: true,
            repeat: -1,
        });

        this.movementTweens.push(tween);
    }

    update(args) {
        if (!this.isWatchingForBall || this.body === undefined) return;
        // check distance

        const distanceToBall = Phaser.Math.Distance.Between(this.x, this.y, this.ball.x, this.ball.y);

        if (this.scene.isKicking) {
            if (distanceToBall < 350) {
                if (!this.isChasing) {
                    this.isChasing = true;
                    this.lastPosition = { x: this.x, y: this.y };
                    this.pauseMovement();
                }
            }
            if (this.isChasing) {
                this.scene.physics.moveToObject(this, this.ball, 200);
            }
        } else {
            if (this.isChasing) {
                this.isChasing = false;
                // Check if lastPosition is defined and move towards it
                if (this.lastPosition) {
                    this.scene.physics.moveTo(this, this.lastPosition.x, this.lastPosition.y, 200);
                    // Once reached the last known position, resume movement
                    this.scene.time.delayedCall(Phaser.Math.Distance.Between(this.x, this.y, this.lastPosition.x, this.lastPosition.y) / 200 * 1000, () => {
                        if (!this || this.body === undefined) {
                            return;
                        }
                        this.setVelocityX(0);
                        this.setVelocityY(0);
                        this.resumeMovement();
                        this.lastPosition = null; // Clear last known position
                    });
                }
            }
        }
    }
}

export default Enemy;
