import {axiosInstance} from '../contexts/AxiosProvider';
import {Chapter} from "../types/chapter";
import {Page} from "../types/pages";
import {PageSequence} from "../types/page-sequence";
import {CompleteChapterResponse} from "../types/complete-chapter-response";

export const fetchChapters = async () => {
    const result = await axiosInstance.get<Chapter[]>("/chapters");
    return result.data;
}

export const fetchAllChapters = async () => {
    const result = await axiosInstance.get<Chapter[]>("/chapters/all");
    return result.data;
}

export const fetchChapterById = async (chapterId: string | number) => {
    const result = await axiosInstance.get<Chapter>("/chapters/chapter/" + chapterId);
    return result.data;
}

export const getChapterPages = async (chapterId: string | number) => {
    const result = await axiosInstance.get<Page[]>("/chapters/chapter/" + chapterId + "/pages");
    return result.data;
}

export const createChapter = async (chapter: Chapter) => {
    const result = await axiosInstance.post<Chapter>("/chapters", chapter);
    return result.data;
}

export const updateChapter = async (chapter: Chapter) => {
    const result = await axiosInstance.put<Chapter>("/chapters/chapter/" + chapter.id, chapter);
    return result.data;
}

export const updateChapterSequences = async (sequences: PageSequence[]) => {
    const result = await axiosInstance.put<Chapter>("/chapters/sequences", sequences);
    return result.data;
}

export const deleteChapter = async (chapterId: number) => {
    const result = await axiosInstance.delete("/chapters/chapter/" + chapterId);
    return result.data;
}

export const completeChapter = async (chapterId: number) => {
    const result = await axiosInstance.put<CompleteChapterResponse>("/chapters/" + chapterId + "/complete");
    return result.data;
}

export const updateChapters = async (chapters: Chapter[]) => {
    const result = await axiosInstance.put<Chapter[]>("/chapters/batch", chapters);
    return result.data;
}

export const getDifficultyUnlocks = async () => {
    const result = await axiosInstance.get<Chapter[]>("/chapters/difficulty-unlocks");
    return result.data;
}

export const fetchCompletedPagesByChapter = async (chapterId: number) => {
    const result = await axiosInstance.get<Page[]>("/chapters/chapter/" + chapterId + "/completed-pages");
    return result.data;
}
