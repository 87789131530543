import { createTheme } from '@mui/material/styles';


const customTheme = createTheme({
    typography: {
        fontFamily: 'Gotham, sans-serif', // Default font family for the entire app

        // @ts-ignore
        fontWeight: {
            black: 900,
            bold: 700,
            regular: 400, // For Gotham Book, you can use the regular weight (400)
            light: 300,
            medium: 500,
            thin: 100,
            ultra: 200,
            xlight: 200,
        },

        // Define font styles for italic variants
        fontStyle: {
            italic: 'italic',
        },

        // Define specific typography variants using Gotham font variations
        h1: {
            fontFamily: 'Gotham Black, sans-serif',
            fontWeight: 900,
        },
        h2: {
            fontFamily: 'Gotham Black, sans-serif',
            fontWeight: 700,
        },
        h3: {
            fontFamily: 'Gotham Black, sans-serif',
            fontWeight: 500,
        },
        h4: {
            fontFamily: 'Gotham Black, sans-serif',
            fontWeight: 400,
        },
        h5: {
            fontFamily: 'Gotham Black, sans-serif',
            fontWeight: 300,
        },
        h6: {
            fontFamily: 'Gotham Black, sans-serif',
            fontWeight: 100,
        },
        subtitle1: {
            fontFamily: 'Gotham Bold, sans-serif',
            fontWeight: 200,
        },
        subtitle2: {
            fontFamily: 'Gotham Regular, sans-serif',
            fontWeight: 200,
        },
        body1: {
            fontFamily: 'Gotham Regular, sans-serif',
            fontWeight: 400,
        },
        body2: {
            fontFamily: 'Gotham Medium, sans-serif',
            fontWeight: 400,
        },
        caption: {
            fontFamily: 'Gotham Light, sans-serif',
            fontWeight: 400,
        },
        button: {
            fontFamily: 'Gotham Black, sans-serif',
            fontWeight: 400,
        },
        // Define other typography variants as needed
        // e.g., body1, body2, caption, button, etc.
    },
    palette: {
        primary: {
            main: '#009CDE',
            dark: '#0D6FB4'
        },
        secondary: {
            main: '#F5851E',
        },
        success: {
            main: '#457205',
        },
        warning: {
            main: '#D21F18'
        },
        background: {
            default: '#FFFFFF',
            paper: '#F0F0F0',
        },
    },
});

export default customTheme;
