import Phaser from "phaser";
import goalSprite from "./sprites/Goal.png";
import playerSprite from './sprites/aurorakicker7.png';
import fieldSprite from "./sprites/field.png";
import soccerBallSprite from './sprites/soccerball.png';
import gameConstants from "./game-constants";
import dustmiteSprite from "./sprites/DUSTMITE.png";
import emotionSprite from "./sprites/EMOTION.png";
import moldSprite from "./sprites/MOLD.png";
import pollenSprite from "./sprites/POLLEN.png";
import strongsmellsSprite from "./sprites/SMELLS.png";
import weatherSprite from "./sprites/WX-WEATHER.png";

export default class DifficultyScene extends Phaser.Scene {
    enemyTypes = [
        {type: 'weather', score: 100},
        {type: 'pollen', score: 250},
        {type: 'strong smells', score: 500},
        {type: 'mold', score: 1000},
        {type: 'emotion', score: 2500},
        {type: 'dust mites', score: 5000}
    ]

    constructor() {
        super('difficulty')
    }

    preload() {
        this.load.image('goal', goalSprite);
        this.load.image('field', fieldSprite);
        this.load.image('player', playerSprite);
        this.load.image('soccerBall', soccerBallSprite);
        this.load.image('dust mites', dustmiteSprite);
        this.load.image('emotion', emotionSprite);
        this.load.image('mold', moldSprite);
        this.load.image('pollen', pollenSprite);
        this.load.image('strong smells', strongsmellsSprite);
        this.load.image('weather', weatherSprite);
    }

    create() {
        const { width, height } = this.scale.gameSize
        const { titleStyle, bodyStyle } = gameConstants.textDecoration;
        const { colors } = gameConstants;
        const centered = width * 0.5;
        let currentButton = null;

        this.createBackground();

        if (!this.game.userSettings) {
            this.add.text(centered, height * 0.5, 'There was a problem connecting to the server. Please try again later.', titleStyle).setOrigin(0.5)
            return;
        }

        this.add.text(centered, height * 0.05, 'Select The T-Force', titleStyle).setOrigin(0.5)
        this.add.text(centered, height * 0.115, 'Each T-Force member will bring their friends before them to the field!', bodyStyle).setOrigin(0.5)

        const enemySelections = [];
        for (let i = 0; i < this.enemyTypes.length; i++) {
            const difficultyLevel = i + 1;
            const y = 0.22 + (i * 0.12);
            const enemyImage = this.add.image(width * 0.2, height * y, this.enemyTypes[i].type).setOrigin(0.5).setScale(0.17)

            if (difficultyLevel > this.game.userSettings.maxDifficulty) {
                enemyImage.setTint(0x000000);
                this.add.text(width * 0.3, height * y, "???", bodyStyle).setOrigin(0, 0.5)
                continue;
            }

            const selected = this.game.userSettings.currentDifficulty === difficultyLevel
            enemySelections.push({difficultyLevel, enemyImage, selected});

            const button = this.createMenuButton(
                this.makeNameCapitalized(this.enemyTypes[i].type),
                () => {
                    enemySelections.forEach((e) => e.selected = false);
                    if (currentButton) {
                        currentButton.setFill(colors.white);
                    }
                    currentButton = button.button;
                    currentButton.setFill(colors.success);
                    enemySelections[difficultyLevel - 1].selected = true;
                },
                0.3, y,
                selected ? colors.success : colors.white,
                47,
                true,
                true,
                selected
            );

            if (selected) {
                currentButton = button.button;
            }

            this.add.text(width * 0.8, height * y, `${this.enemyTypes[i].score}`, bodyStyle).setOrigin(0, 0.5);
        }

        const getSelectedEnemy = () => enemySelections.find(({selected}) => selected);

        this.createMenuButton('Back to Menu', () => this.goBackToMenu(getSelectedEnemy()), 0.5, 0.94, '#ffcf33', 45);
    }

    goBackToMenu(selectedEnemy) {
        console.log(selectedEnemy);

        this.game.socket.emit('difficulty', { userId: this.game.userId, difficulty: selectedEnemy?.difficultyLevel || 1 });

        this.scene.start('menu', {resetSocket: false})
    }

    makeNameCapitalized(name) {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    createBackground() {
        const canvHeight = this.sys.canvas.height / 2;
        const canvWidth = this.sys.canvas.width / 2;

        this.bg = this.add.image(canvWidth, canvHeight, 'field').setOrigin(.5).setScale(1);
        this.bg.setDisplaySize(this.sys.canvas.width * 100, this.sys.canvas.height * 100)
        this.bg.preFX.addBlur();
    }

    createMenuButton(text, onClick, x, y, color, size = 51, alignLeft = false, toggleButton = false, toggleButtonState = false) {
        const { buttonStyle } = gameConstants.textDecoration;
        const { width, height } = this.scale.gameSize
        const toggleButtonResult = { button: null, toggled: null };
        const newButton = this.add.text(width * x, height * y, text, {...buttonStyle, fill: color, fontSize: size})

        if (alignLeft) newButton.setOrigin(0, 0.5).setInteractive();
        else newButton.setOrigin(0.5).setInteractive();

        newButton.on('pointerdown', () => onClick());
        newButton.on('pointerover', () => startHover());
        newButton.on('pointerout', () => stopHover());

        const startHover = () => {
            this.tweens.add({
                targets: newButton,
                scale: { from: 1, to: 1.1},
                duration: 450,
                ease: 'Power2',
                yoyo: true,
                loop: -1
            })
        }

        const stopHover = () => {
            this.tweens.killTweensOf(newButton);
            this.tweens.add({
                targets: newButton,
                scale: 1,
                duration: 300,
                ease: 'Power2',
                onComplete: () => this.tweens.killTweensOf(newButton)
            })
        }

        if (toggleButton) {
            toggleButtonResult.button = newButton;
            toggleButtonResult.toggled = toggleButtonState;
            return toggleButtonResult;
        }

        return newButton;
    }
}
