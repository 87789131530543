import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import {User} from "../../types/user";
import {useSnackbar} from "notistack";

const AdminAuthGuard = ({ component }) => {
    const [status, setStatus] = useState(false);
    const { user, hasCheckedForToken }: { user: User, hasCheckedForToken: boolean } = useContext(AuthContext);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (hasCheckedForToken) {
            checkToken();
        }
    }, [component, hasCheckedForToken]);

    const checkToken = async () => {
        try {
            if (!user || user.role !== "admin") {
                console.log("User is not an admin.")
                enqueueSnackbar("You are not authorized to view this page.", {variant: "error"});
                navigate(`/`);
            }
            setStatus(true);
            return;
        } catch (error) {
            navigate(`/`);
        }
    }

    if (!hasCheckedForToken) {
        return null;
    }

    return status ? <React.Fragment>{component}</React.Fragment> : <React.Fragment></React.Fragment>;
}

export default AdminAuthGuard;
