import {axiosInstance} from '../contexts/AxiosProvider';
import {HighscorePage} from "../types/highscore-page";

export const getHighscores = async (userId: number, time: string, limit = 10, offset = 0) => {
    const result = await axiosInstance.get<HighscorePage>("/highscores", {
        params: { userId, time, limit, offset }
    });

    return result.data;
}

