import React, {useContext, useState} from 'react';
import {
    Avatar,
    Button,
    Checkbox,
    Collapse,
    Container,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Modal,
    Paper,
    Slide,
    Tooltip,
    Typography,
    Zoom
} from '@mui/material';
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import '../ChapterEditor.css';
import {ChapterEditorContext} from "../../../contexts/ChapterEditorContext";
import {ImageCategory} from "../../../types/image-category";
import FolderIcon from '@mui/icons-material/Folder';
import {ImageDataType} from "../../../types/image-data";
import LoadingData from "../../../shared/LoadingData";

function Sidebar() {
    const {
        imageCategories,
        imageSlotData,
        closeSidebar,
        isSidebarOpen,
        bulkPageMode,
        fetchImages,
        isFetchingImages,
    } = useContext(ChapterEditorContext);
    const [selectedIndex, setSelectedIndex] = useState(imageCategories?.map(() => false) || []);
    const [selectedImages, setSelectedImages] = useState<ImageDataType[]>([]);

    // Initialize the state for the subcategories
    const [subSelectedIndex, setSubSelectedIndex] = useState(imageCategories?.map(category => category.subCategories?.map(() => false) || []) || []);

    // Function to handle clicking on a subcategory
    const handleSubListItemClick = (index: number, subIndex: number) => {
        let newSubSelectedIndex = [...subSelectedIndex];
        newSubSelectedIndex[index][subIndex] = !newSubSelectedIndex[index][subIndex];
        setSubSelectedIndex(newSubSelectedIndex);
    };

    React.useEffect(() => {
        if (bulkPageMode) {
            setSelectedImages([]);
        }
    }, [bulkPageMode])

    React.useEffect(() => {
        setSubSelectedIndex(imageCategories?.map(category => category.subCategories?.map(() => false) || []) || []);
    }, [imageCategories])

    const handleFetchImages = () => {
        fetchImages();
    }

    const handleListItemClick = (index: number) => {
        let newSelectedIndex = [...selectedIndex];
        newSelectedIndex[index] = !newSelectedIndex[index];
        setSelectedIndex(newSelectedIndex);
    };

    const handleImageClick = (image: ImageDataType) => {
        if (!bulkPageMode) imageSlotData.selectCallback(image.url);
        else {
            let newSelectedImages = [...selectedImages];
            if (newSelectedImages.includes(image)) {
                newSelectedImages = newSelectedImages.filter((i) => i.id !== image.id);
            } else {
                newSelectedImages.push(image);
            }
            setSelectedImages(newSelectedImages);
        }
    }

    const handleSelectBulkImages = () => {
        imageSlotData.selectCallback(selectedImages);
        closeSidebar();
    }

    return (
        <Modal
            open={isSidebarOpen}
            onClose={closeSidebar}
            aria-labelledby="sidebar-modal"
            aria-describedby="sidebar-modal-description"
        >
            <Container sx={{
                width: 450,
                boxSizing: 'border-box',
                height: "90%",
                position: 'absolute',
                top: '50%',
                left: '100%',
                transform: 'translate(-100%, -50%)',
                overflowY: "scroll"
            }}>
                <Slide direction={"left"} timeout={200} in={isSidebarOpen}>
                    <Paper>
                        <Typography variant="h5" gutterBottom component="div" sx={{p: 2, pb: 0}}>
                            {`Select Image for ` + imageSlotData.label}
                        </Typography>
                        <Button onClick={closeSidebar}>Close</Button>
                        {bulkPageMode && (
                            <Button disabled={selectedImages.length <= 0} color={"success"}
                                    onClick={() => handleSelectBulkImages()}>Select Images</Button>
                        )}
                        <Button onClick={() => handleFetchImages()}>Refresh Images</Button>
                        <Divider/>
                        {isFetchingImages ? (
                            <LoadingData message={"Loading Images"} />
                        ) : (
                            imageCategories?.map((category: ImageCategory, index: number) => (
                                <div key={category.id}>
                                    <ListItem>
                                        <ListItemButton onClick={() => handleListItemClick(index)}>
                                            <ListItemIcon>
                                                <FolderIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={category.name}/>
                                            {selectedIndex[index] ? <ExpandLess/> : <ExpandMore/>}
                                        </ListItemButton>
                                    </ListItem>
                                    <Collapse in={selectedIndex[index]} timeout="auto">
                                        <List component="div" disablePadding>
                                            {category.images.map((image) => (
                                                <ListItem key={image.id} sx={{pl: 4}}>
                                                    <ListItemButton onClick={() => handleImageClick(image)}>
                                                        {bulkPageMode && (
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={selectedImages.includes(image)}
                                                                    disableRipple
                                                                />
                                                            </ListItemIcon>
                                                        )}
                                                        <ListItemAvatar sx={{mr: 1}}>
                                                            <Tooltip placement={"right"} TransitionComponent={Zoom}
                                                                     title={<img src={image.url} alt={image.name}
                                                                                 style={{width: 200}}/>} arrow>
                                                                <Avatar variant={"square"} src={image.url}
                                                                        sx={{width: 70, height: 75}}/>
                                                            </Tooltip>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={image.name}/>
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                            {/* Subcategories */}
                                            {category.subCategories?.map((subCategory: ImageCategory, subIndex: number) => (
                                                <div key={subCategory.id}>
                                                    <ListItem sx={{pl: 4}}>
                                                        <ListItemButton
                                                            onClick={() => handleSubListItemClick(index, subIndex)}>
                                                            <ListItemIcon>
                                                                <FolderIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={subCategory.name}/>
                                                            { subSelectedIndex.length > 0 &&
                                                            subSelectedIndex[index][subIndex] ? <ExpandLess/> : <ExpandMore/>
                                                            }
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <Collapse in={subSelectedIndex.length > 0 && subSelectedIndex[index][subIndex]} timeout="auto">
                                                        <List component="div" disablePadding>
                                                            {subCategory.images.map((image) => (
                                                                <ListItem key={image.id} sx={{pl: 4}}>
                                                                    <ListItemButton onClick={() => handleImageClick(image)}>
                                                                        {bulkPageMode && (
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    edge="start"
                                                                                    checked={selectedImages.includes(image)}
                                                                                    disableRipple
                                                                                />
                                                                            </ListItemIcon>
                                                                        )}
                                                                        <ListItemAvatar sx={{mr: 1}}>
                                                                            <Tooltip placement={"right"}
                                                                                     TransitionComponent={Zoom}
                                                                                     title={<img src={image.url}
                                                                                                 alt={image.name}
                                                                                                 style={{width: 200}}/>}
                                                                                     arrow>
                                                                                <Avatar variant={"square"} src={image.url}
                                                                                        sx={{width: 70, height: 75}}/>
                                                                            </Tooltip>
                                                                        </ListItemAvatar>
                                                                        <ListItemText primary={image.name}/>
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Collapse>
                                                </div>
                                            ))}
                                        </List>
                                    </Collapse>
                                    <Divider/>
                                </div>
                            ))
                        )}
                    </Paper>
                </Slide>
            </Container>
        </Modal>
    )
}

export default Sidebar;
