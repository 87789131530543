import {Alert, Box, Button, CircularProgress, Container, Fade, Paper, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {useMutation} from "react-query";
import {resendEmailCode, verifyEmailCode} from "../../api/auth";
import {AxiosError} from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";

const VerifyEmailPage = () => {
    const [code, setCode] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const {enqueueSnackbar} = useSnackbar();
    const verifyMutation = useMutation('verifyEmail', verifyEmailCode);
    const resendMutation = useMutation('resendEmail', resendEmailCode);

    const handleCodeChange = (event) => {
        setCode(event.target.value);
    };

    const verifyCode = () => {
        verifyMutation.mutate(code, {
            onSuccess: () => {
                enqueueSnackbar("Email verified successfully!", {variant: "success"});
                navigate('/login');
            }
        });
    };

    const resendEmail = () => {
        resendMutation.mutate(location.state.email, {
            onSuccess: () => {
                enqueueSnackbar("Email sent successfully! Please check your email for the new code.", {variant: "success"});
            }
        });
    }

    return (
        <Container sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Fade in={true} timeout={500}>
                <Paper sx={{
                    padding: {
                        xs: 2,
                        sm: 3,
                        md: 4,
                        lg: 5,
                    },
                    width: {
                        xs: "100vw",
                        md: "50vw",
                        lg: "30vw",
                    },
                    margin: {
                        xs: 0,
                        sm: 5,
                    }
                }}>
                    <Typography variant="body1" gutterBottom>
                        Please verify your email address with the code we sent you.
                    </Typography>

                    <Box sx={{my: 1}}>
                        {verifyMutation.isError && (verifyMutation.error as AxiosError).response?.status === 422 && (
                            <Alert severity="error">
                                {/* @ts-ignore */}
                                {(verifyMutation.error as AxiosError).response?.data.message}
                            </Alert>
                        )}
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="code"
                            label="Verification Code"
                            name="code"
                            autoFocus
                            value={code}
                            onChange={handleCodeChange}
                            disabled={verifyMutation.isLoading}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={verifyCode}
                            disabled={verifyMutation.isLoading}
                        >
                            {verifyMutation.isLoading ? <CircularProgress size={24} /> : 'Verify Code'}
                        </Button>
                    </Box>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <Typography variant="body1">
                            Didn't receive an email?
                        </Typography>
                        <Button onClick={resendEmail}>
                            Resend Email
                        </Button>
                    </Box>
                </Paper>
            </Fade>
        </Container>
    )
}

export default VerifyEmailPage;
