import React from "react";
import {Outlet} from "react-router-dom";

const SoccerShootoutWrapper = () => {
    return (
        <Outlet />
    )
}

export default SoccerShootoutWrapper;
