/** @format */

import React from 'react';
import './App.css';
import { Box, Container, Paper, Toolbar, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Navbar from './navbar/Navbar';

const App = () => {
    return <AppLayout />;
};

function AppLayout() {
    return (
        <Box className="App">
            <Box sx={{ minHeight: '100vh' }}>
                <Navbar />
                <Toolbar sx={{ display: { sm: 'none', xs: 'flex' } }} />
                <Box sx={{ paddingTop: '2' }}>
                    <Outlet />
                </Box>
            </Box>
            <Footer />
        </Box>
    );
}

const Footer = () => {
    return (
        <Paper
            sx={{
                width: '100%',
            }}
            component="footer"
            square
            variant="outlined">
            <Container maxWidth="lg">
                <Box
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'center',
                        display: 'flex',
                        mb: 2,
                    }}>
                    <Typography variant="caption" color="initial">
                        Copyright &copy; 2024 etekn. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </Paper>
    );
};

export default App;
