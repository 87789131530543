// Aspect Ratio 16:9 - Portrait

import TextStyle = Phaser.GameObjects.TextStyle;

const MAX_SIZE_WIDTH_SCREEN = 1920
const MAX_SIZE_HEIGHT_SCREEN = 1920
const MIN_SIZE_WIDTH_SCREEN = 300
const MIN_SIZE_HEIGHT_SCREEN = 200

const baseStyle = {
    stroke: '#000',
    fontFamily: 'Arial, sans-serif',
    strokeThickness: 4,
} as TextStyle

const gameConstants = {
    screenBaseSize: {
        maxWidth: MAX_SIZE_WIDTH_SCREEN,
        maxHeight: MAX_SIZE_HEIGHT_SCREEN,
        minWidth: MIN_SIZE_WIDTH_SCREEN,
        minHeight: MIN_SIZE_HEIGHT_SCREEN,
    },
    textDecoration: {
        titleStyle: {
            ...baseStyle,
            fontSize: 91,
            fontStyle: 'bold',
            strokeThickness: 6,
        } as TextStyle,
        h2: {
            ...baseStyle,
            fontSize: 81,
            fontStyle: 'bold',
            strokeThickness: 6,
        } as TextStyle,
        subtitleStyle: {
            ...baseStyle,
            fontSize: 57, // was 48
            strokeThickness: 5,
        } as TextStyle,
        bodyStyle: {
            ...baseStyle,
            fontSize: 47,
            wordWrap: { width: 950 },
        },
        scoreStyle: {
            ...baseStyle,
            fontSize: 48,
        } as TextStyle,
        buttonStyle: {
            ...baseStyle,
            fontSize: 51,
            fill: '#ffcf33',
            fontStyle: 'bold',
            strokeThickness: 5,
        },
        controlButtonStyle: {
            ...baseStyle,
            fontSize: 161,
            fontStyle: 'bold',
            strokeThickness: 5,
        }
    },
    colors: {
        white: "#ffffff",
        secondary: '#ffcf33',
        success: '#a5ff59',
    }
}

export default gameConstants;
