/** @format */

import React from 'react';
import {useLayout} from '../../../hooks/useLayout';
import {Collapse, Typography} from '@mui/material';

const Logo = () => {
    const {showLogo, isOnSoccerShootout} = useLayout();
    return (
        <>
            <Collapse in={showLogo && !isOnSoccerShootout}>
                <AeroLogo />
            </Collapse>
            <Collapse in={showLogo && isOnSoccerShootout}>
                <SoccerShootoutLogo />
            </Collapse>
        </>
    );
};

const AeroLogo = () => (
    <img src={'/logo.jpg'} alt={'logo'} style={{height: '140px', marginBottom: '10px', paddingTop: 2}} />
);

const SoccerShootoutLogo = () => (
    <Typography variant="h4" component="div" sx={{flexGrow: 1}}>
        Soccer Shootout
    </Typography>
);

export default Logo;
