import React, {useContext, useEffect, useMemo, useState} from "react";
import {useSnackbar} from "notistack";
import {List, ListItemButton, ListItemIcon, Typography} from "@mui/material";
import {Check} from "@mui/icons-material";
import {Box} from "@mui/system";
import {ChapterReaderContext} from "./ChapterReaderContext";
import {Quiz} from "../../types/quiz";
import {InteractivePageViewProps} from "./InteractivePageView";

const QuizReader = (props: InteractivePageViewProps) => {
    const {getQuizAnswerOrder, solvePage} = useContext(ChapterReaderContext);
    const [quiz, setQuiz] = useState<Quiz>(props.page.quiz);
    const [foundAnswer, setFoundAnswer] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        setQuiz(props.page.quiz);
        if (props.page.completed) setFoundAnswer(true);
    }, []);

    const randomizedAnswers = useMemo(() => {
        const val = getQuizAnswerOrder(quiz);
        return val;
    }, [quiz]);

    const solve = () => {
        setFoundAnswer(true);
        solvePage(props.page.id);
    }

    const pickAnswer = (answer: string) => {
        if (answer === quiz.answer) {
            solve();
        } else {
            enqueueSnackbar("Whoops! That's not quite right. Please try again.", {variant: 'error'});
        }
    }

    return (
        <Box sx={{
            width: {
                "lg": "70%",
                "sm": "100%",
            },
            minHeight: "20vh",
            overflowWrap: "break-word",
        }}>
            <Typography align="left" variant="h6" component="div" sx={{mt: 2, whiteSpace: "normal"}}>
                {props.page.quiz.question}
            </Typography>
            <List>
                {randomizedAnswers?.map((answer, index) => (
                    <ListItemButton disabled={foundAnswer} key={index} onClick={() => pickAnswer(answer)}>
                        {(foundAnswer && answer === props.page.quiz.answer) && (
                            <ListItemIcon sx={{marginRight: 1, minWidth: "20px"}}>
                                <Check color={"success"}/>
                            </ListItemIcon>
                        )}
                        <Typography sx={{whiteSpace: "normal", fontFamily: 'Gotham Bold, sans-serif'}}>
                            {index + 1}. {answer}
                        </Typography>
                    </ListItemButton>
                ))}
            </List>
        </Box>
    )
};

export default QuizReader;
