import {axiosInstance} from '../contexts/AxiosProvider';
import {Page} from "../types/pages";
import {PageSequence} from "../types/page-sequence";

export const createPages = async (pages: Page[]) => {
    const result = await axiosInstance.post<Page[]>("/pages", {pages});
    return result.data;
}

export const updatePage = async (page: Page) => {
    const result = await axiosInstance.put<Page>("/pages/page/" + page.id, page);
    return result.data;
}

export const updatePageSequences = async (sequences: PageSequence[]) => {
    const result = await axiosInstance.put<Page[]>("/pages/sequences", sequences);
    return result.data;
}

export const deletePage = async (pageId: number) => {
    const result = await axiosInstance.delete("/pages/" + pageId);
    return result.data;
}

export const completePage = async (pageId: number) => {
    const result = await axiosInstance.put("/pages/complete/" + pageId);
    return result.data;
}
