import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {useMutation, useQuery} from "react-query";
import {getResetType, sendResetCode} from "../../api/auth";
import {
    Button,
    Container,
    Fade,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {Box} from "@mui/system";
import {useForm} from "react-hook-form";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {AuthContext} from "../../contexts/AuthContext";
import {AxiosError} from "axios";
import {useSnackbar} from "notistack";


const ResetPage = () => {
    const { logout } = useContext(AuthContext);
    const params = useParams();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const resetCodeQuery = useQuery(['resetCode', params.code], () => getResetType(params.code), {
        refetchInterval: false,
        enabled: false,
    });

    const sendCodeMutation = useMutation(sendResetCode, {
        onSuccess: () => {
            logout(() => navigate('/login'));
            enqueueSnackbar("Your reset was successful. Please log in with your new credentials.", {variant: "success"})
        }, onError: (error: AxiosError) => {
            console.error(error);
            // @ts-ignore
            const message = error.response.data ? error.response.data.message : "An error occurred.";
            enqueueSnackbar("There was a problem: " + message, {variant: "error"});
        }
    });

    useEffect(() => {
        resetCodeQuery.refetch();
    }, []);

    const sendData = (data: { field: string }) => {
        console.log(data)
        sendCodeMutation.mutate({code: params.code, data});
    }

    if (resetCodeQuery.isLoading) {
        return <div>Loading...</div>;
    }

    if (resetCodeQuery.isError || !resetCodeQuery.data) {
        return <div>The reset code is invalid.</div>;
    }

    const resetType = resetCodeQuery.data.type;

    switch(resetType) {
        case "email-reset":
            return <ResetEmail onSubmit={sendData} />;
        case "password-reset":
            return <ResetPassword onSubmit={sendData} />;
        case "username-reset":
            return <ResetUsername onSubmit={sendData} />;
        default:
            return <div>Invalid reset type.</div>;
    }
}

const EntryArea = ({children}) => {
    return (
        <Container sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Fade in={true} timeout={500}>
                <Paper sx={{
                    padding: {
                        xs: 2,
                        sm: 3,
                        md: 4,
                        lg: 5,
                    },
                    width: {
                        xs: "100vw",
                        md: "50vw",
                        lg: "30vw",
                    },
                    margin: {
                        xs: 0,
                        sm: 5,
                    }
                }}>
                    {children}
                </Paper>
            </Fade>
        </Container>
    )
}

interface ResetPanelProps {
    onSubmit: (data: { field: string }) => void;
}

const ResetEmail = ({onSubmit}: ResetPanelProps) => {
    return (
        <EntryArea>
            <Typography variant="body1" gutterBottom>
                Please enter your new email address
            </Typography>
            <SingleFieldEntry label="Email" onSubmit={onSubmit} />
        </EntryArea>
    )
}

const ResetPassword = ({ onSubmit }: ResetPanelProps) => {
    const { handleSubmit, register, formState: { errors }, watch } = useForm();
    const [showPassword, setShowPassword] = useState(false);

    const passwordsMatch = (value) => {
        return value === watch('password') || 'Passwords do not match.';
    };

    const submit = (data) => {
        onSubmit({field: data.password});
    }

    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Fade in={true} timeout={500}>
                <Paper sx={{
                    padding: {
                        xs: 2,
                        sm: 3,
                        md: 4,
                        lg: 5,
                    },
                    width: {
                        xs: "100vw",
                        md: "50vw",
                        lg: "30vw",
                    },
                    margin: {
                        xs: 0,
                        sm: 5,
                    }
                }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h5" gutterBottom>
                            Reset Password
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submit)}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", mt: 2 }}>
                            <TextField
                                type={showPassword ? "text" : "password"}
                                label="Password"
                                fullWidth
                                variant="outlined"
                                {...register("password", {
                                    required: "Password is required.",
                                    minLength: { value: 8, message: "Password must be at least 8 characters long" },
                                    pattern: { value: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/, message: "Password must contain at least one letter and one number" }
                                })}
                                error={!!errors.password}
                                helperText={errors.password?.message.toString()}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", mt: 2 }}>
                            <TextField
                                type={showPassword ? "text" : "password"}
                                label="Confirm Password"
                                fullWidth
                                variant="outlined"
                                {...register("confirmPassword", {
                                    required: "Confirm password is required.",
                                    validate: passwordsMatch
                                })}
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword?.message.toString()}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 2 }}>
                            <Button variant="contained" color="primary" type="submit">
                                Submit
                            </Button>
                        </Box>
                    </form>
                </Paper>
            </Fade>
        </Container>
    );
};

const ResetUsername = ({onSubmit}: ResetPanelProps) => {
    return (
        <EntryArea>
            <Typography variant="body1" gutterBottom>
                Please enter your new username
            </Typography>
            <SingleFieldEntry label="Username" onSubmit={onSubmit} />
        </EntryArea>
    )

}

const SingleFieldEntry = ({ label, onSubmit }) => {
    const { handleSubmit, register } = useForm();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
                label={label}
                variant="outlined"
                fullWidth
                margin="normal"
                {...register("field")}
            />
            <Button type="submit" variant="contained" color="primary">
                Submit
            </Button>
        </form>
    );
};

export default ResetPage;
