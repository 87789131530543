import React, {createContext, useContext, useState} from "react";
import {Box, Fade, Modal} from "@mui/material";

export const PageModalContext = createContext<PageModalContextType | undefined>(undefined);

interface PageModalContextType {
    openPageModal: (pageImageUrl: string) => void;
}

const PageModalProvider = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [pageImageUrl, setPageImageUrl] = useState("");

    const openPageModal = (pageImageUrl) => {
        setPageImageUrl(pageImageUrl);
        setIsOpen(true);
    }

    const value = {
        openPageModal
    }

    return (
        <PageModalContext.Provider value={value}>
            <PageModal pageImageUrl={pageImageUrl} onClose={() => setIsOpen(false)} isOpen={isOpen} />
            {children}
        </PageModalContext.Provider>
    );
}

const usePageModal = () => {
    const context = useContext(PageModalContext);
    const {openPageModal} = context;
    return {openPageModal};
}

const PageModal = ({pageImageUrl, isOpen, onClose}) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            disableAutoFocus={true}
            aria-labelledby="page-modal"
            aria-describedby="page-modal-description">
            <Fade in={isOpen}>
                <Box
                    sx={{
                        height: "80vh",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                    <img src={pageImageUrl} alt={"Page"} style={{height: "100%"}}/>
                </Box>
            </Fade>
        </Modal>
    )
}

export {PageModalProvider, usePageModal}
