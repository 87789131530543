import Phaser from "phaser";
import goalSprite from "./sprites/Goal.png";
import fieldSprite from "./sprites/field.png";
import soccerBallSprite from './sprites/soccerball.png';
import gameConstants from "./game-constants";

export default class GameOverScene extends Phaser.Scene {
    soccerball;

    constructor() {
        super('game-over')

        this.score = 0;
    }

    init(data) {
        this.score = data.score || 0;
        console.log("Init ranks: ", data);
        this.newHighScore = data.ranks?.newHighScore || 0;
        this.globalRank = data.ranks?.globalRank || 0;
        this.personalRank = data.ranks?.personalRank || 0;
    }

    preload() {
        this.load.image('goal', goalSprite);
        this.load.image('field', fieldSprite);
        this.load.image('soccerBall', soccerBallSprite);
    }

    create() {
        const textDecor = {fill: '#fff', stroke: '#000', strokeThickness: 4 };

        const canvHeight = this.sys.canvas.height / 2;
        const canvWidth = this.sys.canvas.width / 2;

        this.bg = this.add.image(canvWidth, canvHeight, 'field').setOrigin(.5).setScale(1);
        this.bg.setDisplaySize(this.sys.canvas.width, this.sys.canvas.height)
        this.bg.preFX.addBlur();

        const soccerBall = this.physics.add.sprite(canvWidth, canvHeight, 'soccerBall');
        soccerBall.preFX.addBlur();

        const { width, height } = this.scale.gameSize
        const { titleStyle, subtitleStyle } = gameConstants.textDecoration;

        const gameOverText = this.add.text(width * 0.5, height * 0.18, 'Game Over!', titleStyle).setOrigin(0.5)

        this.add.text(width * 0.5, height * 0.5, `Score: ${this.score}`, subtitleStyle).setOrigin(0.5)

        this.add.text(width * 0.5, height * 0.56, `Global Rank #${this.globalRank}`, subtitleStyle).setOrigin(0.5)

        this.add.text(width * 0.5, height * 0.62, `Personal Rank #${this.personalRank}`, subtitleStyle).setOrigin(0.5)

        if (this.personalRank === 1) {
            const newPersonalBestText = this.add.text(width * 0.5, height * 0.68, `New Personal Best!`,
                {...subtitleStyle, fill: '#a5ff59'}).setOrigin(0.5)

            this.tweens.add({
                targets: newPersonalBestText,
                angle: { from: -5, to: 5},
                scale: { from: 0.95, to: 1.05},
                duration: 2000,
                ease: 'linear',
                yoyo: true,
                loop: -1
            })
        }

        this.tweens.add({
            targets: gameOverText,
            angle: { from: -5, to: 5, duration: 2000, ease: 'easeInOutSine'},
            scale: { from: 0.95, to: 1.05, duration: 2000, ease: 'Power2'},
            yoyo: true,
            loop: -1
        })

        this.createMenuButton('Back to Menu', () => this.scene.start('menu'), 0.85, '#a5ff59', 71);

        // Set the soccer ball's velocity
        soccerBall.setVelocity(600, 600);
        soccerBall.setScale(0.15);

        // Make the soccer ball bounce when it hits the world bounds
        soccerBall.setCollideWorldBounds(true);
        soccerBall.setBounce(1, 1);

        this.soccerball = soccerBall;
    }

    createMenuButton(text, onClick, y, color, size = 51) {
        const { buttonStyle } = gameConstants.textDecoration;
        const { width, height } = this.scale.gameSize
        const newButton = this.add.text(width * 0.5, height * y, text, {...buttonStyle, fill: color, fontSize: size}).setOrigin(0.5).setInteractive();
        newButton.on('pointerdown', () => onClick());
        newButton.on('pointerover', () => startHover());
        newButton.on('pointerout', () => stopHover());

        const startHover = () => {
            this.tweens.add({
                targets: newButton,
                scale: { from: 1, to: 1.1},
                duration: 450,
                ease: 'Power2',
                yoyo: true,
                loop: -1
            })
        }

        const stopHover = () => {
            this.tweens.killTweensOf(newButton);
            this.tweens.add({
                targets: newButton,
                scale: 1,
                duration: 300,
                ease: 'Power2',
                onComplete: () => this.tweens.killTweensOf(newButton)
            })
        }
    }

    update() {
        this.soccerball.angle += 6;
    }
}
