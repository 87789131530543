import {Box} from "@mui/system";
import {Button, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {Page} from "../../../types/pages";
import {Delete, ResetTv, SaveAs} from "@mui/icons-material";
import ImageSelectField from "../ImageSelectField";
import {useTheme} from "@mui/material/styles";
import QuizPageDisplay from "./QuizPageDisplay";
import PuzzlePageDisplay from "./PuzzlePageDisplay";


interface PageDisplayProps {
    page: Page;
    updatePage: (page: Page) => void;
    deletePage: () => void;
}

export interface DisplayProps {
    tmpPage: Page;
    updateTempState: (newState: Page) => void;
}

function PageDisplay({page: selectedPage, updatePage, deletePage}: PageDisplayProps) {
    const theme = useTheme();

    const [tmpPage, setTmpPage] = React.useState(selectedPage);

    useEffect(() => {
        resetTempPage();
    }, [selectedPage]);

    const resetTempPage = () => {
        setTmpPage(selectedPage);
    }

    const handleStateChange = (newState: Page) => {
        setTmpPage(newState);
    }

    const saveChanges = () => {
        updatePage(tmpPage);
    }

    return (
        <Box sx={{padding: 4, display: "flex"}}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                borderColor: theme.palette.grey.A200,
                borderRightWidth: "1px",
                borderRightStyle: "solid",
                width: "12%",
                mr: 2,
            }}>
                <Typography variant="h5" gutterBottom>
                    {"Page #" + selectedPage.sequence}
                </Typography>

                <Button startIcon={<SaveAs/>} disabled={tmpPage === selectedPage} onClick={() => saveChanges()}
                        color="primary">
                    Save Changes
                </Button>
                <Button onClick={() => resetTempPage()} startIcon={<ResetTv/>} disabled={tmpPage === selectedPage} color="secondary">Reset</Button>
                <Button onClick={() => deletePage()} startIcon={<Delete/>} color="error">Delete</Button>
            </Box>

            <Box sx={{width: "100%", justifyContent: "flex-start", alignItems: "center", display: "flex"}}>
                {selectedPage.puzzle && <PuzzlePageDisplay updateTempState={handleStateChange} tmpPage={tmpPage} />}
                {selectedPage.quiz && <QuizPageDisplay updateTempState={handleStateChange} tmpPage={tmpPage} />}
                {selectedPage.imageUrl && <ComicPageDisplay updateTempState={handleStateChange} tmpPage={tmpPage}  />}
            </Box>
        </Box>
    )
}

const ComicPageDisplay = ({tmpPage, updateTempState}: DisplayProps) => (
    <Box sx={{justifyContent: "center"}}>
        <Box
            sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"}}>
            <ImageSelectField
                onImageChange={(url) => updateTempState({...tmpPage, imageUrl: url})}
                label={"Comic Page"}
                imageUrl={tmpPage.imageUrl}
                size={"md"}
            />
        </Box>
    </Box>
)

export default PageDisplay;
