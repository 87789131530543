import Phaser from "phaser";
import goalSprite from "./sprites/Goal.png";
import playerSprite from './sprites/aurorakicker7.png';
import fieldSprite from "./sprites/field.png";
import soccerBallSprite from './sprites/soccerball.png';
import gameConstants from "./game-constants";

export default class HelpScene extends Phaser.Scene {
    constructor() {
        super('help')
    }

    preload() {
        this.load.image('goal', goalSprite);
        this.load.image('field', fieldSprite);
        this.load.image('player', playerSprite);
        this.load.image('soccerBall', soccerBallSprite);
    }

    create() {
        const { width, height } = this.scale.gameSize
        const { titleStyle, bodyStyle } = gameConstants.textDecoration;
        const centered = width * 0.5;

        this.createBackground();

        this.add.text(centered, height * 0.12, 'How to Play', titleStyle).setOrigin(0.5)

        this.add.text(centered, height * 0.25, 'Your mission is to help Aurora score as many goals as you can while helping her avoid the T-Force at all costs!', bodyStyle).setOrigin(0.5)

        const playerImg = this.image = this.add.image(centered, height * 0.42, 'player').setOrigin(0.5).setScale(0.4)
        this.tweens.add({
            targets: playerImg,
            angle: { from: -5, to: 5},
            duration: 450,
            ease: 'easeInOutSine',
            yoyo: true,
            loop: -1
        });
        this.tweens.add({
            targets: playerImg,
            x: { from: centered + 10, to: centered - 10},
            duration: 2000,
            ease: 'easeInOutSine',
            yoyo: true,
            loop: -1
        });

        this.add.text(centered, height * 0.55, 'Use the arrow or the A and D keys to move Aurora left and right.', bodyStyle).setOrigin(0.5)

        this.add.image(width * 0.7, height * 0.68, 'player').setOrigin(0.5).setScale(0.4)
        this.soccerball = this.add.image(width * 0.4, height * 0.67, 'soccerBall').setOrigin(0.5).setScale(0.15)

        this.add.text(centered, height * 0.8, 'Hold the SPACEBAR to aim and let go to kick!', bodyStyle).setOrigin(0.5)

        this.createMenuButton('Back to Menu', () => this.scene.start('menu', {resetSocket: false}), 0.9, '#ffcf33', 45);
    }

    createBackground() {
        const canvHeight = this.sys.canvas.height / 2;
        const canvWidth = this.sys.canvas.width / 2;

        this.bg = this.add.image(canvWidth, canvHeight, 'field').setOrigin(.5).setScale(1);
        this.bg.setDisplaySize(this.sys.canvas.width * 100, this.sys.canvas.height * 100)
        this.bg.preFX.addBlur();
    }

    createMenuButton(text, onClick, y, color, size = 51) {
        const { buttonStyle } = gameConstants.textDecoration;
        const { width, height } = this.scale.gameSize
        const newButton = this.add.text(width * 0.5, height * y, text, {...buttonStyle, fill: color, fontSize: size}).setOrigin(0.5).setInteractive();
        newButton.on('pointerdown', () => onClick());
        newButton.on('pointerover', () => startHover());
        newButton.on('pointerout', () => stopHover());

        const startHover = () => {
            this.tweens.add({
                targets: newButton,
                scale: { from: 1, to: 1.1},
                duration: 450,
                ease: 'Power2',
                yoyo: true,
                loop: -1
            })
        }

        const stopHover = () => {
            this.tweens.killTweensOf(newButton);
            this.tweens.add({
                targets: newButton,
                scale: 1,
                duration: 300,
                ease: 'Power2',
                onComplete: () => this.tweens.killTweensOf(newButton)
            })
        }
    }

    update() {
        this.soccerball.angle -= 1;
    }
}
