/** @format */

import {Page} from '../../types/pages';
import {Box, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import React from 'react';
import QuizReader from './QuizReader';
import PuzzleReader from './PuzzleReader';
import {Info} from '@mui/icons-material';

export interface InteractivePageViewProps {
    page: Page;
    onSolvePage: (pageId: number) => void;
}
const InteractivePageView = React.memo(
    (props: InteractivePageViewProps) => {
        const getPuzzleHelp = () => {
            return 'For keyboard navigation: Use TAB or hover over the panel you want to select and press the SPACE bar. Then use the arrow keys to move the panel around.';
        };

        const Wrapper = ({children, label, isPuzzle}) => (
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'left',
                        padding: 2,
                    }}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography variant={'h4'} component={'div'} sx={{marginTop: 2}} align="left">
                            {label}
                        </Typography>
                        {isPuzzle && (
                            <Tooltip title={getPuzzleHelp()}>
                                <IconButton size="small">
                                    <Info fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                    {children}
                </Box>
            </Grid>
        );

        if (props.page.quiz) {
            return (
                <Wrapper isPuzzle={false} label={'QUIZ'}>
                    <QuizReader page={props.page} onSolvePage={props.onSolvePage} />
                </Wrapper>
            );
        }

        return (
            <Wrapper isPuzzle={true} label={'CARTOON PUZZLE'}>
                <PuzzleReader page={props.page} onSolvePage={props.onSolvePage} />
            </Wrapper>
        );
    },
    (prevProps, nextProps) => prevProps.page.id === nextProps.page.id,
);

export default InteractivePageView;
