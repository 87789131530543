import {Page} from "../../types/pages";
import {Box, Grid} from "@mui/material";
import React, {useEffect} from "react";
import {PageModalContext, PageModalProvider, usePageModal} from "./PageModalContext";

interface PageViewProps {
    page: Page;
    altText: string;
}
const PageView = (props: PageViewProps) => {
    const {openPageModal} = usePageModal();

    return (
        <Grid item xs={12} sm={6} md={4}>
            <Box sx={{
                height: "100%", overflow: "hidden",
                transform: 'translateY(0)',
                transition: 'transform 0.05s ease-in-out',
                '&:hover': {
                    transform: 'translateY(-4px)',
                },
            }}
                 onClick={() => openPageModal(props.page.imageUrl)}
            >
                <img
                    className={"image-box"}
                    src={props.page.imageUrl}
                    alt={props.altText}
                />
            </Box>
        </Grid>
    )
}

export default PageView;
