import {Box, Container, LinearProgress, Typography} from "@mui/material";
import React from "react";

interface LoadingDataProps {
    message: string;
}

const LoadingData = (props: LoadingDataProps) => {
    return (
        <Container>
            <Box sx={{padding: 5}}>
                <Typography variant="h5" component="div" className="chapterTitle">
                    {`Loading ${props.message}...`}
                </Typography>
                <LinearProgress/>
            </Box>
        </Container>
    );
}

export default LoadingData;
