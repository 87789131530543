/** @format */

import {useTheme} from '@mui/material/styles';
import {useCallback, useContext} from 'react';
import {AuthContext} from '../../../contexts/AuthContext';
import {AppBar, Box, Button, Container, Drawer, IconButton, List, ListItem, Toolbar, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {useConfirmationModal} from '../../../contexts/ConfirmationModalContext';
import {useLayout} from '../../../hooks/useLayout';
import {NavItem} from '../../../types/nav-item';
import Logo from './Logo';

interface NavbarProps {
    window?: () => Window;
}

const drawerWidth = 240;

const Divider = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: '2px',
                height: '14px',
                backgroundColor: theme.palette.secondary.main,
            }}
        />
    );
};

const NavListItem = (props: {item: NavItem}) => {
    const theme = useTheme();

    if (props.item.actionCallback) {
        return (
            <Button onClick={props.item.actionCallback} sx={{my: 2, color: theme.palette.text.primary}}>
                {props.item.label}
            </Button>
        );
    }

    return (
        <Link to={props.item.url}>
            <Button sx={{my: 2, color: theme.palette.text.primary}}>{props.item.label}</Button>
        </Link>
    );
};

const Navbar = (props: NavbarProps) => {
    const {window} = props;

    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const {user, logout} = useContext(AuthContext);
    const {ConfirmationModal, promptModal} = useConfirmationModal();
    const {isOnSoccerShootout} = useLayout();

    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const handleLogout = useCallback(() => {
        promptModal('Logout', 'Are you sure you want to logout?', () => logout(handleLogoutSuccess));
    }, []);

    const handleLogoutSuccess = () => {
        enqueueSnackbar('Logged out successfully!', {variant: 'success'});
        navigate('/');
    };

    const baseNavItem: NavItem = {
        label: 'Home',
        url: '/',
    };

    const unAuthenticatedNavItems: NavItem[] = [
        {
            label: 'Login',
            url: '/login',
        },
        {
            label: 'Register',
            url: '/register',
        },
    ];

    const authenticatedNavItems: NavItem[] = [
        {
            label: 'Soccer Shootout',
            url: '/soccer-shootout/game',
        },
        {
            label: 'Account',
            url: '/account',
        },
        {
            label: 'Logout',
            actionCallback: handleLogout,
        },
    ];

    const leaderboardItem: NavItem = {
        label: 'Leaderboard',
        url: '/soccer-shootout/leaderboard',
    };

    const editorItem: NavItem = {
        label: 'Editor',
        url: '/chapter-select',
    };

    const getNavItems = () => {
        if (!user) return unAuthenticatedNavItems;

        let items = [...authenticatedNavItems];

        if (user.role === 'admin') items.splice(items.length - 2, 0, editorItem);

        if (isOnSoccerShootout) items.splice(1, 0, leaderboardItem);

        return items;
    };

    const handleDrawerToggle = () => {
        setIsDrawerOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
            <Typography variant="h6" sx={{my: 2}}>
                Decoding Asthma - Aero Decoder
            </Typography>
            <Divider />
            <List>
                <>
                    <ListItem disablePadding>
                        <NavListItem item={baseNavItem} />
                    </ListItem>
                    {getNavItems().map((item, index) => (
                        <ListItem key={index} disablePadding>
                            <NavListItem item={item} />
                        </ListItem>
                    ))}
                </>
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const styles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <>
            <ConfirmationModal />
            <Container>
                <Box sx={{...styles, flexDirection: 'column', display: {xs: 'none', sm: 'flex'}}}>
                    <Logo />
                    <Box sx={{...styles, flexDirection: 'row'}}>
                        <>
                            <NavListItem item={baseNavItem} />
                            {getNavItems().map((item, index) => (
                                <React.Fragment key={index}>
                                    <Divider />
                                    <NavListItem key={index} item={item} />
                                </React.Fragment>
                            ))}
                        </>
                    </Box>
                    <NavbarBorder />
                </Box>
                <AppBar component="nav" color="default" sx={{display: {xs: 'block', sm: 'none'}}}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{mr: 2, display: {sm: 'none'}}}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            container={container}
                            variant="temporary"
                            open={isDrawerOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: {xs: 'block', sm: 'none'},
                                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                            }}>
                            {drawer}
                        </Drawer>
                    </Toolbar>
                </AppBar>
            </Container>
        </>
    );
};

const NavbarBorder = () => (
    <Box
        sx={{
            height: '10px',
            width: '100%',
            backgroundColor: 'primary.main',
        }}
    />
);

export default Navbar;
