import React, {createContext, useContext, useState} from 'react';
import {getImagesByCategoryId} from "../api/image";
import {useQuery} from "react-query";
import {ImageDataType} from "../types/image-data";
import {AuthContext} from "./AuthContext";
import {ImageCategory} from '../types/image-category';

export const ChapterEditorContext = createContext<ChapterEditorContextType>({} as ChapterEditorContextType);

interface ChapterEditorContextType {
        imageCategories: ImageCategory[],
        isSidebarOpen: boolean,
        imageSlotData: any,
        openSidebar: (label: string, selectCallback: (url: string | ImageDataType[]) => void, openInBulkMode?: boolean) => void,
        closeSidebar: () => void,
        bulkPageMode: boolean,
        fetchImages: () => void,
        isFetchingImages: boolean,
}

export const ChapterEditorProvider = ({children}) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [bulkPageMode, setBulkPageMode] = useState(false);
    const [imageSlotData, setImageSlotData] = useState({});
    const { user } = useContext(AuthContext);

    // noinspection RedundantConditionalExpressionJS
    const imageCategoryQuery = useQuery('images', () => getImagesByCategoryId(), {
        // Only run the query if the user is an admin
        enabled: false,
        refetchInterval: false,
        refetchOnMount: false,
    });

    const fetchImages = () => {
        if (user && user.role === 'admin')
            imageCategoryQuery.refetch();
    }

    const openSidebar = (label: string, selectCallback: (url: string | ImageDataType[]) => void, openInBulkMode?: boolean) => {
        setImageSlotData({label, selectCallback});
        setSidebarOpen(true);
        if (openInBulkMode)
            setBulkPageMode(true);
    }

    const closeSidebar = () => {
        setImageSlotData({});
        setSidebarOpen(false);
        setBulkPageMode(false);
    }

    const value: ChapterEditorContextType = {
        imageCategories: imageCategoryQuery.data,
        isSidebarOpen: sidebarOpen,
        imageSlotData,
        openSidebar,
        closeSidebar,
        bulkPageMode,
        fetchImages,
        isFetchingImages: imageCategoryQuery.isLoading,
    }

    return (
        <ChapterEditorContext.Provider value={value}>
            {children}
        </ChapterEditorContext.Provider>
    );
}
