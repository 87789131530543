import {Chapter} from "../../types/chapter";
import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Grow,
    IconButton,
    LinearProgress,
    Skeleton,
    Tooltip,
    Typography
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {
    Add,
    ArrowBack,
    ArrowForward,
    Delete,
    Edit,
    MoodBad,
    PublishedWithChanges,
    Save,
    Unpublished
} from "@mui/icons-material";
import {Box} from "@mui/system";
import {createChapter, deleteChapter, fetchAllChapters, updateChapter, updateChapterSequences} from "../../api/chapter";
import {useMutation, useQuery, useQueryClient} from "react-query";
import CreateChapterModal from "./create-chapter-modal/CreateChapterModal";
import EnemyUnlockModal from "./enemy-unlock-modal/EnemyUnlockModal";
import {useSnackbar} from "notistack";

function ChapterSelector() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();
    const [isCreateChapterModalOpen, setIsCreateChapterModalOpen] = useState<boolean>(false);

    const [chapterSequences, setChapterSequences] = useState<Chapter[]>([]);
    const [isSequenceChanged, setIsSequenceChanged] = useState<boolean>(false);

    const [isEnemyUnlockModalOpen, setIsEnemyUnlockModalOpen] = useState(false);
    const closeEnemyUnlockModal = () => {
        queryClient.invalidateQueries('all-chapters');
        setIsEnemyUnlockModalOpen(false);
    }

    const chaptersQuery = useQuery('all-chapters', fetchAllChapters)

    const updateChapterMutation = useMutation(updateChapter, {
        onSuccess: () => {
            setIsCreateChapterModalOpen(false);
            queryClient.invalidateQueries('all-chapters');
            enqueueSnackbar("Successfully updated chapter", {variant: "success"});
        },
        onError: (error) => {
            console.error(error);
            enqueueSnackbar("There was a problem updating this chapter", {variant: "error"});
        }
    });

    const createChapterMutation = useMutation(createChapter, {
        onSuccess: () => {
            setIsCreateChapterModalOpen(false);
            queryClient.invalidateQueries('all-chapters');
            enqueueSnackbar("Successfully created chapter", {variant: "success"});
        },
        onError: (error) => {
            console.error(error);
            enqueueSnackbar("There was a problem creating this chapter", {variant: "error"});
        }
    });

    const deleteChapterMutation = useMutation(deleteChapter, {
        onSuccess: () => {
            setIsCreateChapterModalOpen(false);
            queryClient.invalidateQueries('all-chapters');
            enqueueSnackbar("Successfully deleted chapter", {variant: "success"});
        },
        onError: (error) => {
            console.error(error);
            enqueueSnackbar("There was a problem deleting this chapter", {variant: "error"});
        }
    });

    const chapterSequenceUpdateMutation = useMutation(updateChapterSequences, {
        onSuccess: () => {
            queryClient.invalidateQueries('all-chapters');
            enqueueSnackbar("Chapter sequence updated successfully", {variant: "success"});
        },
        onError: (error) => {
            console.error(error);
            enqueueSnackbar("There was a problem updating this chapter sequence", {variant: "error"});
        }
    });

    const resetChapterSequences = () => {
        setChapterSequences(chaptersQuery.data.map((chapter, index) => ({
            ...chapter,
            sequence: index + 1,
        })));
        setIsSequenceChanged(false);
    }

    useEffect(() => {
        if (chaptersQuery.status === "success") {
            resetChapterSequences();
        }
    }, [chaptersQuery.data]);

    const publishChapter = (chapter: Chapter) => {
        updateChapterMutation.mutate({
            ...chapter,
            isDraft: !chapter.isDraft,
        })
    }

    const moveChapterSequence = (chapter: Chapter, direction: "up" | "down") => {
        let newSequence = direction === "up" ? chapter.sequence - 1 : chapter.sequence + 1;

        newSequence = newSequence < 0 ? 0 : newSequence;
        newSequence = newSequence > chaptersQuery.data.length ? chaptersQuery.data.length : newSequence;

        setChapterSequences(chapterSequences.map(c => {
            if (c.id === chapter.id) {
                return {...c, sequence: newSequence};
            } else if (direction === "up" && c.sequence < chapter.sequence && c.sequence >= newSequence) {
                return {...c, sequence: c.sequence + 1};
            } else if (direction === "down" && c.sequence > chapter.sequence && c.sequence <= newSequence) {
                return {...c, sequence: c.sequence - 1};
            } else {
                return c;
            }
        }));

        setIsSequenceChanged(true);
    }

    const saveChapterSequences = () => {
        chapterSequenceUpdateMutation.mutate(chapterSequences);
        setIsSequenceChanged(false);
    }

    const isMaxSequence = (chapter: Chapter) => {
        if (!chaptersQuery.data) return false;
        return chaptersQuery.data.reduce((max, p) => p.sequence > max ? p.sequence : max, chaptersQuery.data[0].sequence) === chapter.sequence;
    }

    const isMinSequence = (chapter: Chapter) => {
        if (!chaptersQuery.data) return false;
        return chaptersQuery.data.reduce((min, p) => p.sequence < min ? p.sequence : min, chaptersQuery.data[0].sequence) === chapter.sequence;
    }

    const handleDeleteChapter = (chapter: Chapter) => {
        deleteChapterMutation.mutate(chapter.id);
    }

    const onCreateChapter = (chapter: Chapter) => {
        createChapterMutation.mutate(chapter);
    }

    const onModalOpen = () => {
        setIsCreateChapterModalOpen(true)
    }

    const onModalClose = () => {
        setIsCreateChapterModalOpen(false)
    }

    if (chaptersQuery.status === "loading") {
        return (
            <Container>
                <Box sx={{padding: 5}}>
                    <Typography variant="h5" component="div" className="chapterTitle">
                        Loading Chapters...
                    </Typography>
                    <LinearProgress/>
                </Box>
            </Container>
        )
    }

    return (
        <>
            <EnemyUnlockModal
                isModalOpen={isEnemyUnlockModalOpen}
                onClose={closeEnemyUnlockModal}
                allChapters={chaptersQuery.data}
            />
            <CreateChapterModal onCreate={onCreateChapter} isOpen={isCreateChapterModalOpen} onClose={onModalClose}/>
            <Container>
                <Box sx={{padding: 5}}>
                    <Typography variant="h5" component="div" className="chapterTitle">
                        Chapter Editor
                    </Typography>
                    <Grid container spacing={2}>
                        {chapterSequences ? chapterSequences.sort((a, b) => a.sequence - b.sequence).map((chapter, index) =>
                            <Grow in={true} timeout={300 + (index * 100)} key={index}>
                                <Grid item xs={6}>
                                    <Card variant={"outlined"} sx={{display: 'flex'}}>
                                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                            <CardMedia
                                                component="img"
                                                image={chapter.coverImageUrl}// or default img when item's img not present
                                                height="200"
                                                alt={"Image Not Found"}
                                            />
                                        </Box>
                                        <Box sx={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
                                            <CardContent>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between'
                                                }}>
                                                    <Typography gutterBottom variant="body1" component="div">
                                                        {chapter.title}
                                                    </Typography>
                                                    {chapter.isDraft && (
                                                        <Typography gutterBottom variant="body1" color={"warning.main"}
                                                                    component="div">
                                                            Draft
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    {chapter.description}
                                                </Typography>
                                            </CardContent>
                                            <Box sx={{display: 'flex', alignItems: 'center', pl: 1, pb: 1}}>
                                                <Link to={`/chapter-editor/${chapter.id}`}>
                                                    <Tooltip title={"Edit"}>
                                                        <IconButton>
                                                            <Edit/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Link>
                                                <Tooltip title={chapter.isDraft ? "Publish" : "Unpublish"}>
                                                    <IconButton onClick={() => publishChapter(chapter)}>
                                                        {chapter.isDraft ? (
                                                            <PublishedWithChanges/>
                                                        ) : (
                                                            <Unpublished/>
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                                {chapter.isDraft && (
                                                    <Tooltip title={"Delete"}>
                                                        <IconButton onClick={() => handleDeleteChapter(chapter)}>
                                                            <Delete/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Box>
                                            <Box sx={{display: 'flex', alignItems: 'center', pl: 1, pb: 1}}>
                                                {!isMinSequence(chapter) && (
                                                    <Tooltip title={"Move Back in Order"}>
                                                        <IconButton onClick={() => moveChapterSequence(chapter, "up")}>
                                                            <ArrowBack />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {!isMaxSequence(chapter) && (
                                                    <Tooltip title={"Move Forward in Order"}>
                                                        <IconButton onClick={() => moveChapterSequence(chapter, "down")}>
                                                            <ArrowForward />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grow>
                        ) : (
                            <Grid item xs={6}>
                                <Skeleton animation="wave"/>
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <Button startIcon={<Add/>} onClick={onModalOpen}>Create New Chapter</Button>
                            <Button onClick={saveChapterSequences} sx={{marginLeft: 2}} startIcon={<Save/>} disabled={!isSequenceChanged}>
                                Save Chapter Order
                            </Button>
                            <Button onClick={() => setIsEnemyUnlockModalOpen(true)} startIcon={<MoodBad />}
                                    color="warning">
                                Set Enemy Unlock
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}

export default ChapterSelector;
