import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useAuth from "../shared/useAuth";

// Create an instance of axios
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const AxiosProvider = ({ children }) => {
    const [isAxiosReady, setIsAxiosReady] = useState(false);

    useEffect(() => {
        // Add a request interceptor
        const requestInterceptor = axiosInstance.interceptors.request.use((config) => {
            // Get the token from local storage
            let token = localStorage.getItem('token');

            if (!token) token = sessionStorage.getItem('token');

            // If the token exists, set it in the headers
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            return config;
        }, (error) => {
            // If there's an error, reject the promise
            return Promise.reject(error);
        });

        setIsAxiosReady(true);

        // Clean up interceptors when component unmounts
        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
        };
    }, []);

    return <>{isAxiosReady && children}</>;
};

export { axiosInstance, AxiosProvider };
