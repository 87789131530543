import {Alert, Box, Button, CircularProgress, Container, Fade, Paper, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {useMutation} from "react-query";
import {sendResetRequest} from "../../api/auth";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const sendEmailMutation = useMutation('sendEmail', sendResetRequest);

    const [email, setEmail] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const submitEmail = () => {
        sendEmailMutation.mutate({type: 'password-reset', email}, {
            onSuccess: () => {
                enqueueSnackbar("Password reset sent successfully! Please check your email for the reset code.", {variant: "success"});
                navigate('/login');
            }
        });
    }

    return (
        <Container sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Fade in={true} timeout={500}>
                <Paper sx={{
                    padding: {
                        xs: 2,
                        sm: 3,
                        md: 4,
                        lg: 5,
                    },
                    width: {
                        xs: "100vw",
                        md: "50vw",
                        lg: "30vw",
                    },
                    margin: {
                        xs: 0,
                        sm: 5,
                    }
                }}>
                    <Typography variant="body1" gutterBottom>
                        Please enter your email address to reset your password.
                    </Typography>

                    <Box sx={{my: 1}}>
                        {sendEmailMutation.isError && (sendEmailMutation.error as AxiosError).response?.status === 422 && (
                            <Alert severity="error">
                                {/* @ts-ignore */}
                                {(sendEmailMutation.error as AxiosError).response?.data.message}
                            </Alert>
                        )}
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="code"
                            label="Email Address"
                            name="code"
                            autoFocus
                            value={email}
                            onChange={handleEmailChange}
                            disabled={sendEmailMutation.isLoading}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={submitEmail}
                            disabled={sendEmailMutation.isLoading}
                        >
                            {sendEmailMutation.isLoading ? <CircularProgress size={24} /> : 'Request Password Reset'}
                        </Button>
                    </Box>
                </Paper>
            </Fade>
        </Container>
    )
}

export default ForgotPasswordPage;
