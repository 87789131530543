import React, {createContext, useContext, useState} from "react";
import {Quiz} from "../../types/quiz";
import {useMutation, useQueryClient} from "react-query";
import {completePage} from "../../api/page";
import {useSnackbar} from "notistack";
import {AuthContext} from "../../contexts/AuthContext";

export const ChapterReaderContext = createContext<ChapterReaderContextType | undefined>(undefined);

interface ChapterReaderContextType {
    setQuizAnswerOrder: (quizId: number, order: string[]) => void;
    getQuizAnswerOrder: (quiz: Quiz) => string[];
    quizAnswerOrders: Record<number, string[]>;
    setCurrentChapterId: (chapterId: number) => void;
    solvePage: (pageId: number) => void;
}

export const ChapterReaderProvider = ({children, onSolvePage}) => {
    const queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();
    const {user} = useContext(AuthContext);

    const [quizAnswerOrders, setQuizAnswerOrders] = useState([]);
    const [currentChapterId, setCurrentChapterId] = useState(null);

    const completePageMutation = useMutation(completePage, {
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['chapter-pages', currentChapterId]});
        },
        onError: (error) => {
            console.error(error);
            enqueueSnackbar("There was a problem completing this page", {variant: "error"});
        }
    });

    const setQuizAnswerOrder = (quizId, order) => {
        setQuizAnswerOrders({...quizAnswerOrders, [quizId]: order});
    }

    const getQuizAnswerOrder = (quiz: Quiz) => {
        return quizAnswerOrders[quiz.id];
    }

    const solvePage = (pageId: number) => {
        if (user) completePageMutation.mutate(pageId);
        enqueueSnackbar("Correct!", {variant: 'success'});
        onSolvePage(pageId);
    }

    const value = {
        setQuizAnswerOrder,
        getQuizAnswerOrder,
        quizAnswerOrders,
        setCurrentChapterId,
        solvePage,
    }

    return (
        <ChapterReaderContext.Provider value={value}>
            {children}
        </ChapterReaderContext.Provider>
    );
}
