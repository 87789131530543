import {axiosInstance} from '../contexts/AxiosProvider';
import {UserStats} from "../types/user-stats";

export interface LoginProps {
    email: string;
    username: string;
    password: string;
}

export const login = async (loginProps: LoginProps) => {
    const result = await axiosInstance.post("/auth/login", loginProps);
    return result.data;
}

export const register = async (loginProps: LoginProps) => {
    const result = await axiosInstance.post("/auth/register", loginProps);
    return result.data;
}

export const verifyEmailCode = async (code: string) => {
    const result = await axiosInstance.post('/auth/verify-email/', {code});
    return result.data;
}

export const resendEmailCode = async (email: string) => {
    const result = await axiosInstance.post("/auth/resend-verification-email", {email});
    return result.data;
}

export const blockUser = async (idToBlock: number) => {
    const result = await axiosInstance.post("/auth/block-user", {idToBlock});
    return result.data;
}

export const unblockUser = async (idToUnblock: number) => {
    const result = await axiosInstance.put("/auth/unblock-user", {idToUnblock});
    return result.data;
}

export const getUserStats = async () => {
    const result = await axiosInstance.get<UserStats>("/auth/statistics");
    return result.data;
}

export interface SendResetRequestProps {
    type: ResetRequestTypes;
    email?: string;
}
export type ResetRequestTypes = "password-reset" | "email-reset" | "username-reset";
export const sendResetRequest = async (props: SendResetRequestProps) => {
    const result = await axiosInstance.post("/auth/reset", {type: props.type, email: props?.email});
    return result.data;
}

export const getResetType = async (code: string) => {
    const result = await axiosInstance.get(`/auth/reset/${code}`);
    return result.data;
}

export type SendResetCodeData = {
    code: string;
    data: { field: string };
}
export const sendResetCode = async ({code, data}: SendResetCodeData) => {
    const result = await axiosInstance.post(`/auth/reset/${code}`, {data});
    return result.data;
}

export const validateToken = async () => {
    const result = await axiosInstance.get("/auth/validate-token");
    return result.data;
}
