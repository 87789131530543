import React, {useEffect, useMemo} from "react";
import {
    Box,
    Button,
    Container, FormControl,
    Grid,
    InputLabel,
    Modal,
    Paper,
    Select,
    Typography
} from "@mui/material";
import {useMutation, useQueryClient} from "react-query";
import {updateChapters} from "../../../api/chapter";
import MenuItem from "@mui/material/MenuItem";
import {Chapter} from "../../../types/chapter";
import {useSnackbar} from "notistack";

interface EnemyUnlockModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    allChapters: Chapter[];
}

type Enemy = {
    name: string;
    imgUrl: string;
}

export const enemies: Enemy[] = [
    {
        name: "Weather",
        imgUrl: '/WX-WEATHER.png',
    },
    {
        name: "Pollen",
        imgUrl: '/POLLEN.png',
    },
    {
        name: "Strong Smells",
        imgUrl: '/SMELLS.png',
    },
    {
        name: "Mold",
        imgUrl: '/MOLD.png',
    },
    {
        name: "Emotion",
        imgUrl: '/EMOTION.png',
    },
    {
        name: "Dust mite",
        imgUrl: '/DUSTMITE.png',
    },
]

const EnemyUnlockModal: React.FC<EnemyUnlockModalProps> = ({isModalOpen, onClose, allChapters}) => {
    const queryClient = useQueryClient();
    const [chapters, setChapters] = React.useState<Chapter[]>(allChapters);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (!isModalOpen) return;
        setChapters(allChapters);
    }, [isModalOpen]);

    const updateEnemyUnlockChapterMutation = useMutation(updateChapters, {
        onSuccess: () => {
            queryClient.invalidateQueries("all-chapters");
            enqueueSnackbar("Enemy Unlocks updated successfully", {variant: "success"});
            handleClose();
        }, onError: (error) => {
            enqueueSnackbar("Error updating Enemy Unlocks: " + error, {variant: "error"});
        }
    })

    const onSave = useMemo(() => () => {
        updateEnemyUnlockChapterMutation.mutate(chapters);
    }, [chapters]);

    const setChapterAtIndex = (index: number, chapter: Chapter) => {
        const newChapters = [...chapters];
        newChapters[index] = chapter;
        setChapters(newChapters);
    }

    const onSelectChapter = (chapter: Chapter) => {
        const index = chapters.findIndex((c) => c.id === chapter.id);
        if (index !== -1) {
            setChapterAtIndex(index, chapter);
        } else {
            console.error("Chapter not found in list");
        }
    }

    const resetChapter = (chapter: Chapter) => {
        const index = chapters.findIndex((c) => c.id === chapter.id);
        if (index !== -1) {
            setChapters(prevChapters => {
                const newChapters = [...prevChapters];
                newChapters[index] = allChapters.find((c) => c.id === chapter.id);
                return newChapters;
            });
        } else {
            console.error("Chapter not found in list");
        }
    }

    const resetState = useMemo(() => () => {
        setChapters(allChapters);
    }, [allChapters]);

    const handleClose = () => {
        resetState();
        onClose();
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={handleClose}
            aria-labelledby="enemy-unlocked-modal"
            aria-describedby="enemy-unlocked-modal-description"
        >
            <Container sx={{
                width: "75vw",
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Paper sx={{padding: 2}}>
                    <Typography variant="h6" id="modal-title">
                        Enemy Unlocks
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: "column",
                        maxHeight: "70vh",
                        minHeight: "55vh",
                        overflowX: "auto",
                        padding: 2
                    }}>
                        <Grid container spacing={2}>
                            {enemies.map((enemy, index) => (
                                <Grid item xs={12} sm={6} key={index}>
                                    <EnemyUnlock enemy={enemy} difficultyLevel={index + 1} chapters={chapters}
                                                 onSelectChapter={onSelectChapter} resetChapter={resetChapter}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box
                        sx={{marginTop: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Button onClick={onClose}>Close</Button>
                        <Button variant={"outlined"} color={"success"} onClick={onSave}>Save</Button>
                    </Box>
                </Paper>
            </Container>
        </Modal>
    );
}

interface EnemyUnlockProps {
    enemy: Enemy;
    difficultyLevel: number;
    chapters: any[];
    onSelectChapter: (chapter: Chapter) => void;
    resetChapter: (chapter: Chapter) => void;
}

const EnemyUnlock = ({enemy, difficultyLevel, chapters, onSelectChapter, resetChapter}: EnemyUnlockProps) => {
    const [selectedChapter, setSelectedChapter] = React.useState<Chapter | null>(null);

    useEffect(() => {
        const difficultyUnlocks = chapters.find((chapter) => chapter.difficultyUnlock === difficultyLevel);
        if (!difficultyUnlocks) {
            setSelectedChapter(null);
            return;
        }

        setSelectedChapter(difficultyUnlocks);
    }, [chapters]);

    const handleChapterChange = (event) => {
        let chapter = chapters.find((c) => c.id === event.target.value);

        if (selectedChapter !== null) {
            resetChapter(selectedChapter);
            if (chapter === undefined) {
                const c = {...selectedChapter};
                c.difficultyUnlock = null;
                setSelectedChapter(null);
                onSelectChapter(c);
                return;
            }
        }

        chapter.difficultyUnlock = difficultyLevel;
        setSelectedChapter(chapter);
        onSelectChapter(chapter);
    };

    return (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: "flex-start"}}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2}}>
                <img src={"/enemy-pictures" + enemy.imgUrl} alt={enemy.name} style={{width: 100, height: 100}}/>
                <Typography variant={"body1"}>{difficultyLevel + ". " + enemy.name}</Typography>
            </Box>
            <FormControl fullWidth>
                { difficultyLevel === 1 ?
                    <DefaultedSelect />
                    :
                    <ChapterSelect
                        selectedChapter={selectedChapter}
                        chapters={chapters}
                        handleChapterChange={handleChapterChange}
                    />
                }
            </FormControl>
        </Box>
    )
}

interface ChapterSelectProps {
    selectedChapter: Chapter;
    chapters: Chapter[];
    handleChapterChange: (event: any) => void;
}

const ChapterSelect = ({selectedChapter, chapters, handleChapterChange}: ChapterSelectProps) => {
    return (
        <>
            <InputLabel id="selected-chapter">Chapter</InputLabel>
            <Select
                labelId={"selected-chapter"}
                label="Select Chapter"
                value={selectedChapter?.id || -1}
                onChange={handleChapterChange}
            >
                <MenuItem value={-1}>
                    <em>None</em>
                </MenuItem>
                {chapters.map((chapter, index) => (
                    <MenuItem key={index} value={chapter.id}>{chapter.title}</MenuItem>
                ))}
            </Select>
        </>
    )
}

const DefaultedSelect = () => {
    return (
        <>
            <InputLabel id="selected-chapter">Chapter</InputLabel>
            <Select
                labelId={"selected-chapter"}
                label="Select Chapter"
                disabled={true}
                value={0}
            >
                <MenuItem value={0}>Available by Default</MenuItem>
            </Select>
        </>
    )
}

export default EnemyUnlockModal;
