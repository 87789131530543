import React, {forwardRef, useContext, useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Paper,
    SelectChangeEvent,
    TextField,
    Typography, useMediaQuery
} from '@mui/material';
import {Box} from '@mui/system';
import {Page} from "../../types/pages";
import {useTheme} from '@mui/material/styles';
import {DragDropContext, Draggable} from 'react-beautiful-dnd';
import {StrictModeDroppable} from "../../shared/StrictModeDroppable";
import {Add, AddToPhotos, QuestionMark, Save} from "@mui/icons-material";
import PageDisplay from "./page-display/PageDisplay";
import AddPageModal from "./add-page-modal/AddPageModal";
import {PageType} from "./add-page-modal/PageTypeDropdown";
import {useParams} from "react-router-dom";
import {fetchChapterById, getChapterPages, updateChapter,} from "../../api/chapter";
import {PageSequence} from '../../types/page-sequence';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {createPages, deletePage, updatePage, updatePageSequences} from "../../api/page";
import Sidebar from "./sidebar/SideBar";
import {ChapterEditorContext} from "../../contexts/ChapterEditorContext";
import {ImageDataType} from "../../types/image-data";
import {useConfirmationModal} from "../../contexts/ConfirmationModalContext";
import ImageSelectField from "./ImageSelectField";
import {useSnackbar} from "notistack";

const BoxRef = forwardRef((props, ref) => (
    <Box ref={ref} {...props} />
));

function ChapterEditor() {
    const theme = useTheme();
    const params = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const chapterQuery = useQuery(['chapter', params.chapterId], () => fetchChapterById(params.chapterId));
    const pagesQuery = useQuery(['pages-by-chapter', params.chapterId], () => getChapterPages(params.chapterId));

    // @ts-ignore
    const {openSidebar} = useContext(ChapterEditorContext);
    // @ts-ignore
    const { promptModal } = useConfirmationModal();

    const [selectedPage, setSelectedPage] = useState<Page | null>(null);
    const [tempChapter, setTempChapter] = useState(null);
    const [pageType, setPageType] = React.useState<PageType>('comic');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageSequences, setPageSequences] = useState<PageSequence[]>([]);
    const [sortedPages, setSortedPages] = useState<Page[]>([]);
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const resetPageSequences = () => {
        setPageSequences(pagesQuery.data.map((page, index) => ({
            id: page.id,
            sequence: index + 1,
        })));
    }

    const pageSaveMutation = useMutation(createPages, {
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['pages-by-chapter', params.chapterId]});
            enqueueSnackbar("Page created successfully" + 'pages-by-chapter: ' + params.chapterId, {variant: "success"});
        },
        onError: (error) => {
            console.error(error);
            enqueueSnackbar("There was a problem creating this page", {variant: "error"});
        }
    });

    const pageUpdateMutation = useMutation(updatePage, {
        onSuccess: (updatedPage: Page) => {
            queryClient.invalidateQueries({queryKey: ['pages-by-chapter', params.chapterId]});
            enqueueSnackbar("Page updated successfully", {variant: "success"});

            // If the updated page id matches the selected page id, update the selected page state
            if (selectedPage && selectedPage.id === updatedPage.id) {
                setSelectedPage(updatedPage);
            }
        },
        onError: (error: Error) => {
            console.error(error);
            enqueueSnackbar("There was a problem updating this page", {variant: "error"});
        }
    });

    const pageSequenceUpdateMutation = useMutation(updatePageSequences, {
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['pages-by-chapter', params.chapterId]});
            enqueueSnackbar("Page sequence updated successfully", {variant: "success"});
        },
        onError: (error) => {
            console.error(error);
            enqueueSnackbar("There was a problem updating this page sequence", {variant: "error"});
        }
    });

    const chapterUpdateMutation = useMutation(updateChapter, {
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['chapter', params.chapterId]});
            enqueueSnackbar("Chapter updated successfully", {variant: "success"});
        },
        onError: (error) => {
            console.error(error);
            enqueueSnackbar("There was a problem updating this chapter", {variant: "error"});
        }
    });

    const deletePageMutation = useMutation(deletePage, {
        onSuccess: () => {
            queryClient.invalidateQueries(['pages-by-chapter']);
            enqueueSnackbar("Page deleted successfully", {variant: "success"});
            setSelectedPage(null);
        },
        onError: (error) => {
            console.error(error);
            enqueueSnackbar("There was a problem deleting this page", {variant: "error"});
        }
    });

    const handleDeletePage = () => {
        promptModal("Delete Page", "Are you sure you want to delete this page?", () => {
            deletePageMutation.mutate(selectedPage.id);
        });
    }

    useEffect(() => {
        if (pagesQuery.status === "success") {
            resetPageSequences();

            setSortedPages(pagesQuery.data ? pagesQuery.data.sort((a, b) => {
                const aSequence = pageSequences.find(seq => seq.id === a.id)?.sequence || 0;
                const bSequence = pageSequences.find(seq => seq.id === b.id)?.sequence || 0;
                return aSequence - bSequence;
            }) : []);
        }
    }, [pagesQuery.data]);

    useEffect(() => {
        if (pageSequences.length > 0) {
            setSortedPages(pagesQuery.data ? pagesQuery.data.sort((a, b) => {
                const aSequence = pageSequences.find(seq => seq.id === a.id)?.sequence || 0;
                const bSequence = pageSequences.find(seq => seq.id === b.id)?.sequence || 0;
                return aSequence - bSequence;
            }) : []);
        }
    }, [pageSequences]);

    if (chapterQuery.status === 'loading' || pagesQuery.status === 'loading') {
        return <CircularProgress/>;
    }

    if (chapterQuery.status === "success" && !tempChapter) {
        setTempChapter(chapterQuery.data);
    }

    const addNewPage = (page: Page) => {
        closeModal();
        pageSaveMutation.mutate([page]);
    }

    const handleAddMultiplePagesClick = () => {
        openSidebar("Pages in Bulk", handleMultiplePageSelect, true);
    };

    const handleMultiplePageSelect = (images: ImageDataType[]) => {
        const pages = images.map((image, index) => ({
            id: -1,
            chapterId: chapterQuery.data.id,
            imageUrl: image.url,
            sequence: pagesQuery.data.length + index + 1,
            completed: false,
        }));

        pageSaveMutation.mutate(pages);
    };

    const onTitleChange = (event) => setTempChapter({...tempChapter, title: event.target.value});
    const saveChapterChanges = () => {
        promptModal("Save Changes", "Are you sure you want to save these changes?", () => {
            chapterUpdateMutation.mutate(tempChapter);
        });
    }

    const openModal = () => {
        setSelectedPage(null);
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);

    const handlePageTypeSelect = (event: SelectChangeEvent) => {
        let pageType: PageType = event.target.value as PageType;
        setPageType(pageType);
    };

    const handleUpdatePage = (page: Page) => {
        promptModal("Save Changes", "Are you sure you want to save these changes?", () => {
            pageUpdateMutation.mutate(page);
        });
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items: Page[] = Array.from(pagesQuery.data);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // create a new array of page sequences
        const newPageSequences = items.map((item, index) => ({
            id: item.id,
            sequence: index + 1,
        }));

        setPageSequences(newPageSequences);
        setSortedPages(items);
    }

    const handleChapterCoverImageChange = (url: string) => {
        setTempChapter({...tempChapter, coverImageUrl: url});
    }

    const savePageSequences = () => {
        setSelectedPage(null);
        pageSequenceUpdateMutation.mutate(pageSequences);
    }

    const getUniqueKey = (id: number, key: number) => (id + '-' + key);

    return (
        <>
            <AddPageModal
                isModalOpen={isModalOpen}
                chapterId={chapterQuery.data.id}
                onClose={closeModal}
                addPage={addNewPage}
                pageTypeSelected={pageType}
                onPageTypeSelect={handlePageTypeSelect}
            />
            {!isModalOpen && (
                <Sidebar/>
            )}
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Box sx={{display: 'flex', flex: 1}}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: matches ? 'calc(100vh - 100px)' : 'calc(100vh - 150px)', // Adjust the height based on the screen size
                            flexGrow: 1,
                            overflow: 'hidden',
                            padding: 2
                        }}
                    >
                        {tempChapter && (
                            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", mb: 2}}>
                                <TextField onChange={onTitleChange} sx={{width: 400}} size={"small"}
                                           value={tempChapter ? tempChapter.title : ''} label="Title"/>
                                <ImageSelectField
                                    onImageChange={handleChapterCoverImageChange}
                                    label={"Chapter Cover Image"}
                                    imageUrl={""}
                                    showPreview={false}
                                />
                                <Button disabled={tempChapter.title === chapterQuery.data.title && tempChapter.coverImageUrl === chapterQuery.data.coverImageUrl}
                                        onClick={() => saveChapterChanges()} sx={{marginLeft: 2, marginRight: 2}} startIcon={<Save/>}
                                        variant="contained"
                                        color="success">
                                    Save Chapter
                                </Button>
                            </Box>
                        )}
                        <Box
                            sx={{
                                flexGrow: 1,
                                overflowY: 'auto',
                                padding: '3',
                                height: '100%'
                            }}
                        >
                            <Paper sx={{height: '100%'}}>
                                {selectedPage && (
                                    <PageDisplay page={selectedPage} updatePage={handleUpdatePage}
                                                 deletePage={handleDeletePage}/>
                                )}
                            </Paper>
                        </Box>

                        <StrictModeDroppable droppableId="pages" direction="horizontal">
                            {(provided) => (
                                //@ts-ignore
                                <BoxRef
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    sx={{
                                        width: "100%",
                                        height: '275px',
                                        padding: 4,
                                        overflowX: 'auto',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexGrow: 0,
                                        flexWrap: 'nowrap',
                                    }}
                                >
                                    {sortedPages.map((page, index) => (
                                        <Draggable key={getUniqueKey(page.id, index) + "-page"}
                                                   draggableId={getUniqueKey(page.id, index) + "-page"}
                                                   index={index}>
                                            {(provided) => (
                                                //@ts-ignore
                                                <BoxRef sx={{
                                                    width: {xs: '100px', sm: '150px'},
                                                    marginRight: '16px',
                                                    flexShrink: 0,
                                                    outline: `solid ${theme.palette.primary.main}`,
                                                    outlineWidth: '0px',
                                                    transform: 'translateY(0)',
                                                    transition: 'all 0.05s ease-in-out',
                                                    '&:hover': {
                                                        outlineWidth: `2px`,
                                                        transform: 'translateY(-4px)',
                                                    },
                                                }}
                                                        onClick={() => setSelectedPage(page)}
                                                        key={page.id}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                >
                                                    <Card variant={'outlined'} sx={{width: '100%', height: '100%'}}>
                                                        {page.quiz ? (
                                                            <QuestionMark sx={{
                                                                width: '100%',
                                                                height: {xs: '50px', sm: '100px'},
                                                                marginBottom: 6,
                                                                marginTop: 6
                                                            }}/>
                                                        ) : (
                                                            <CardMedia
                                                                component="img"
                                                                src={page.puzzle ? page.puzzle.imageUrl : page.imageUrl}
                                                                alt={`Page ${page.id}`}
                                                                sx={{height: {xs: '50px', sm: '100px'}}}
                                                            />
                                                        )}
                                                        <CardContent>
                                                            <Typography>
                                                                {page.puzzle ? "Puzzle" : page.quiz ? "Quiz" : "Page"}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                # {pageSequences.find(seq => seq.id === page.id)?.sequence}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </BoxRef>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </BoxRef>
                            )}
                        </StrictModeDroppable>
                        <Box sx={{display: "flex", flexDirection: "row"}}>
                            <Button onClick={openModal} sx={{marginLeft: 2}} startIcon={<Add/>}
                                    variant="contained"
                                    color="success">
                                Add Page
                            </Button>
                            <Button onClick={handleAddMultiplePagesClick} sx={{marginLeft: 2}}
                                    startIcon={<AddToPhotos/>}
                                    variant="contained"
                                    color="success">
                                Add Multiple Pages
                            </Button>
                            <Button onClick={savePageSequences} sx={{marginLeft: 2}} startIcon={<Save/>}>
                                Save Page Order
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DragDropContext>
        </>
    );
}


export default ChapterEditor;
